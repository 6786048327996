import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";

import SocketContext from "./context/SocketContext";
import { StateProvider } from "./context/store";

import { socket } from "./context/socket";

import reportWebVitals from "./reportWebVitals";

require('dotenv').config()

const history = createBrowserHistory();

ReactDOM.render(
  <StateProvider>
    <SocketContext.Provider value={socket}>
      <Router history={history}>
        <App />
      </Router>
    </SocketContext.Provider>
  </StateProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
