import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";

import SnackbarCustomized from "./common/Snackbar";
import { store } from "../context/store";
import { deviceSize } from "./common/Breakpoints";
import {
  createCompany,
  updateCompany,
  getCompanyInfo,
  getMyTermsImage
} from "../services/companyService";

import CheckIcon from "@material-ui/icons/Check";

function SettingsFormAdminCompany({ id, onCloseCompanyForm }) {
  const { state, dispatch } = useContext(store);
  const [companyId, setCompanyId] = useState("");
  const [domain, setDomain] = useState("");
  const [name, setName] = useState("");
  const [subscription, setSubscription] = useState(false);
  const [baseUrl, setBaseUrl] = useState("");
  const [textMessage, setTextMessage] = useState("");
  const [termsImage, setTermsImage] = useState("");
  const [termsUrl, setTermsUrl] = useState("");
  const [sendMessagesActive, setSendMessagesActive] = useState(false);
  const [recognitionActive, setRecognitionActive] = useState(false);
  const [maxNumberOfSubscriptions, setMaxNumberOfSubscriptions] = useState(null);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("#caf0f8");
  const [snackbarBgColor, setSnackbarBgColor] = useState("");

  useEffect(async () => {
    if (id && id !== null) {
      const response = await getCompanyInfo(id);
      if (response && response.status === 200) {
        setCompanyId(id);
        setDomain(response.data.domain);
        setName(response.data.name);
        setSubscription(response.data.subscription);
        setBaseUrl(response.data.baseUrl);
        setTextMessage(response.data.textMessage);
        setTermsUrl(response.data.termsUrl);
        setMaxNumberOfSubscriptions(response.data.maxNumberOfSubscriptions);
        setSendMessagesActive(response.data.sendMessagesActive);
        setRecognitionActive(response.data.imageRecognitionActive);
        setIsLoading(false);
      } else {
        setOpenSnackbar(true);
        setSnackbarMessage(response);
        setSnackbarBgColor("#ff8484");
      }

      const imageResponse = await getMyTermsImage(id);
      if (imageResponse && imageResponse.status === 200) {
        setDefaultImage(imageResponse.data);
      } else {
        setOpenSnackbar(true);
        setSnackbarMessage(imageResponse);
        setSnackbarBgColor("#ff8484");
      }
    }
  }, [id]);

  const [selectedImage, setSelectedImage] = useState(null);
  const [defaultImage, setDefaultImage] = useState('images/default.jpg');

  // Create a reference to the hidden file input element
  const hiddenFileInput = React.useRef(null);

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleFileRemove = event => {
    setSelectedImage(null);
    setTermsImage(null);
  };
  const handleFileClick = event => {
    hiddenFileInput.current.click();
  };
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);
    setTermsImage(file);
  };

  const closeSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (companyId) {
      const data = {
        companyId,
        name,
        subscription,
        baseUrl,
        textMessage,
        termsImage,
        termsUrl,
        maxNumberOfSubscriptions,
        sendMessagesActive,
        recognitionActive
      };
      const response = await updateCompany(data);
      if (response && response.status === 200) {
        onCloseCompanyForm();
      } else {
        setOpenSnackbar(true);
        setSnackbarMessage(response);
        setSnackbarBgColor("#ff8484");
      }
    } else {
      const data = {
        domain,
        name,
        subscription,
        baseUrl,
        textMessage,
        termsImage,
        termsUrl,
        maxNumberOfSubscriptions,
        sendMessagesActive,
        recognitionActive
      };
      const response = await createCompany(data);
      if (response && response.status === 200) {
        onCloseCompanyForm();
      } else {
        setOpenSnackbar(true);
        setSnackbarMessage(response);
        setSnackbarBgColor("#ff8484");
      }
    }
  };

  if (id && isLoading) {
    return <Container />;
  }

  return (
    <Container>
      <SnackbarCustomized
        onOpen={openSnackbar}
        onCloseSnackbarParent={closeSnackbar}
        message={snackbarMessage}
        backgroundcolor={snackbarBgColor}
        textcolor={"#495057"}
      />
      <StyledForm
        onSubmit={(e) => {
          handleSubmit(e);
        }}
      >
        {!id && (
          <div>
            <StyledTextLabel htmlFor="domain">Domain:</StyledTextLabel>
            <br />
            <StyledTextInput
              id="domain"
              name="domain"
              type="text"
              value={domain}
              onChange={(e) => setDomain(e.target.value)}
            />
            <br />
          </div>
        )}
        <StyledTextLabel htmlFor="name">name:</StyledTextLabel>
        <br />
        <StyledTextInput
          id="name"
          name="name"
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <br />
        <StyledTextLabel htmlFor="baseUrl">Base url:</StyledTextLabel>
        <br />
        <StyledTextInput
          id="baseUrl"
          name="baseUrl"
          type="text"
          value={baseUrl}
          onChange={(e) => setBaseUrl(e.target.value)}
        />
        <br />
        <StyledTextLabel htmlFor="textMessage">Text message:</StyledTextLabel>
        <br />
        <StyledTextInput
          id="textMessage"
          name="textMessage"
          type="text"
          value={textMessage}
          onChange={(e) => setTextMessage(e.target.value)}
        />
        <br />
        <StyledTextLabel htmlFor="textMessage">Terms & Agreements URL:</StyledTextLabel>
        <br />
        <StyledTextInput
          id="termsUrl"
          name="termsUrl"
          type="text"
          value={termsUrl}
          onChange={(e) => setTermsUrl(e.target.value)}
        />
        <br />
        <StyledTextLabel htmlFor="maxNumberOfSubscriptions">
          Max amount of subscriptions:
        </StyledTextLabel>
        <br />
        <StyledTextInput
          id="maxNumberOfSubscriptions"
          name="maxNumberOfSubscriptions"
          type="number"
          value={maxNumberOfSubscriptions}
          onChange={(e) => setMaxNumberOfSubscriptions(e.target.value)}
        />
        <CheckBoxInputContainer>
          <p>Subscription:</p>
          <RoundedToggleButton
            backgroundColor={subscription ? "#04a1c4" : "#fff"}
            onClick={() => setSubscription(!subscription)}
          >
            {subscription && <CheckIcon />}
          </RoundedToggleButton>
        </CheckBoxInputContainer>
        <CheckBoxInputContainer>
          <p>Messages active:</p>
          <RoundedToggleButton
            backgroundColor={sendMessagesActive ? "#04a1c4" : "#fff"}
            onClick={() => setSendMessagesActive(!sendMessagesActive)}
          >
            {sendMessagesActive && <CheckIcon />}
          </RoundedToggleButton>
        </CheckBoxInputContainer>
        <CheckBoxInputContainer>
          <p>Image recognition active:</p>
          <RoundedToggleButton
            backgroundColor={recognitionActive ? "#04a1c4" : "#fff"}
            onClick={() => setRecognitionActive(!recognitionActive)}
          >
            {recognitionActive && <CheckIcon />}
          </RoundedToggleButton>
        </CheckBoxInputContainer>
        <br />
        <ImageUploadContainer>
          <p>Terms & agreements image:</p>
          {(
            <div>
              {selectedImage ? <img alt="Not found" width={"100%"} src={URL.createObjectURL(selectedImage)} /> :
                <img alt="Default not found" width={"100%"} src={`${process.env.PUBLIC_URL}/${defaultImage}`} />}
              <br />
            </div>
          )}
          <br />
        </ImageUploadContainer>
        <StyledSubmitContainer>

          <StyledSmallButton type="button" onClick={handleFileClick}>
            Upload a file
          </StyledSmallButton>

          <StyledSmallButton type="reset" onClick={handleFileRemove}>Remove</StyledSmallButton>

          <input
            type="file"
            ref={hiddenFileInput}
            onChange={handleFileChange}
            style={{ display: 'none' }}
          />
        </StyledSubmitContainer>

        <br />
        {error && <ErrorSpan>{error}</ErrorSpan>}
        {id ? (
          <StyledSubmitContainer>
            <StyledSubmit type="submit" value="Update" />
          </StyledSubmitContainer>
        ) : (
          <StyledSubmitContainer>
            <StyledSubmit type="submit" value="Save" />
          </StyledSubmitContainer>
        )}
      </StyledForm>
    </Container>
  );
}

export default SettingsFormAdminCompany;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  /* overflow: scroll; */
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 10px;
  background-color: white;
  padding-left: 30px;
  padding-top: 15px;
  padding-bottom: 15px;
  @media (max-width: ${deviceSize.tablet}px) {
    padding-left: 0px;
  }
`;

const StyledForm = styled.form`
  width: 100%;
`;

const StyledTextLabel = styled.label`
  font-family: "Comfortaa";
  font-size: 11px;
  color: #77727a;
  font-weight: 300;
  position: relative;
  top: 9px;
  left: 19px;
  background-color: white;
`;

const StyledTextInput = styled.input`
  width: 50%;
  height: 40px;
  min-width: 300px;
  max-width: 500px;
  font-family: "Comfortaa";
  flex: 1;
  outline: 0;
  border: 1px solid whitesmoke;
  border-radius: 8px;
  background-color: white;
  padding: 0 12px;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0.5px;
  color: #303030;
  @media (max-width: ${deviceSize.tablet}px) {
    min-width: 200px;
  }
  @media (max-width: ${deviceSize.mobile}px) {
    min-width: 150px;
  }
`;

const StyledTextArea = styled.textarea``;

const StyledFieldSet = styled.fieldset``;

const StyledSubmitContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 30px;
  margin-right: 50px;
  margin-bottom: 30px;
`;

const StyledSubmit = styled.input`
  border-radius: 4px;
  text-decoration: none;
  border: none;
  text-align: center;
  font-family: "Comfortaa";
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.5px;
  padding: 13px 13px;
  background-color: #04a1c4;
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;

  :hover {
    opacity: 0.7;
  }
  :active {
    transform: scale(1.05);
    box-shadow: rgba(0, 0, 0, 0.18) 2px 6px 10px;
  }
`;

const ErrorSpan = styled.span`
  font-family: "montserrat";
  font-style: italic;
  font-size: 12px;
  font-weight: 300;
  margin-top: 8px;
  margin-bottom: 8px;
  color: #333;
`;

const CheckBoxInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 40px;
  width: 20%;
  max-width: 200px;
  min-width: 150px;
  margin: 15px 0;

  & > p {
    flex: 1;
    font-family: "Comfortaa";
    font-size: 12px;
    color: gray;
    font-weight: 300;
    margin-right: 25px;
    margin-left: 12px;
  }
`;

const ImageUploadContainer = styled.div`
  display: flex;
  flex-direction: col;
  justify-content: flex-start;
  align-items: center;
  width: 95%;
  margin: 15px 0;

  & > p {
    flex: 1;
    font-family: "Comfortaa";
    font-size: 12px;
    color: gray;
    font-weight: 300;
    margin-right: 25px;
    margin-left: 12px;
  }
`;

// Style the Button component
const StyledSmallButton = styled.button`
  border-radius: 4px;
  text-decoration: none;
  border: none;
  text-align: center;
  font-family: "Comfortaa";
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 0.5px;
  padding: 11px 11px;
  background-color: #04a1c4;
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
  margin-left: 1rem;

  :hover {
    opacity: 0.7;
  }
  :active {
    transform: scale(1.05);
    box-shadow: rgba(0, 0, 0, 0.18) 2px 6px 10px;
  }
`;

const RoundedToggleButton = styled.div`
  display: flex;
  height: 20px;
  width: 20px;
  border-radius: 4px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.backgroundColor || "#04a1c4"};
  border: 1px solid #04a1c4;
  box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  :hover {
    opacity: 0.7;
  }
  & .MuiSvgIcon-root {
    font-size: 16px;
    color: #fff;
  }
`;
