import React from "react";
import styled from "styled-components";

const NotFound = () => {
  return (
    <Container>
      <SubContainer>
        <h1>404</h1>
        <h2>The page you requested was not found.</h2>
        <Logo src={`${process.env.PUBLIC_URL}/assets/images/logo_pegamento_text.jpg`} />
      </SubContainer>
    </Container>
  );
};

export default NotFound;

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const SubContainer = styled.div`
  /* height: 100vh; */
  width: 35%;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > h1 {
    font-family: "Comfortaa";
    font-weight:100;
    font-size: 42px;
    margin-bottom: 20px;
  }

  & > h2 {
    font-family: "Comfortaa";
    text-align: center;
    font-weight:100;
  }
`;

const Logo = styled.img`
  object-fit: contain; // keep aspect ratio
  height: 60px;
  margin-top: 25px;
`;
