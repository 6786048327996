import axios from "axios";
import jwtDecode from "jwt-decode";
import { getJwt, verifyExpiration } from "./authService";

axios.defaults.withCredentials = true;

export async function getCompanyReport(company, startDate, endDate) {
  const response = await axios.get("/api/report/company", { params: { company: company, startDate: startDate, endDate: endDate } });
  return response;
}

export async function getAllCompanyReport(startDate, endDate) {
  const response = await axios.get("/api/report/company/all", { params: { startDate: startDate, endDate: endDate } });
  return response;
}

export async function getAgentReportForCompany(company, startDate, endDate) {
  const response = await axios.get("/api/report/agent", { params: { company: company, startDate: startDate, endDate: endDate } });
  return response;
}