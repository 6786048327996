import React, { useContext, useEffect, useState } from "react";
import "./App.css";
import "./styles/css/va.css";
import { Switch, Route, Redirect } from "react-router-dom";
import styled from "styled-components";
import { store } from "./context/store";
import auth from "./services/authService";

import CustomerVideo from "./pages/CustomerVideo";
import DashboardVideo from "./pages/DashboardVideo";
import DashboardSettings from "./pages/DashboardSettings";

import { deviceSize } from "./components/common/Breakpoints";
import ProtectedRoute from "./components/ProtectedRoute";
import RedirectAfterCall from "./components/RedirectAfterCall";
import Login from "./components/Login";
import NotFound from "./components/NotFound";
import DashboardPerformance from './pages/DashboardPerformance';

function App() {
  const { state, dispatch } = useContext(store);
  const projectVersion = process.env.REACT_APP_VERSION

  useEffect(async () => {
    try {
      const response = await auth.isAuthenticated();
      if (response.data.authenticated === false) {
        auth.logout();
        dispatch({
          type: "LOGOUT",
        });
      } else if (response.data.authenticated === true) {
        dispatch({
          type: "LOGIN",
          payload: response.data
        });
      }
    } catch (err) {
      console.log(err);
    }
  }, []);

  return (
    <div className="App">
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/chat/:peerId/:roomId?" component={CustomerVideo} />
        <Route path="/callended" component={RedirectAfterCall} />
        <ProtectedRoute path="/dashboard/home" component={DashboardVideo} />
        <ProtectedRoute path="/dashboard/performance" component={DashboardPerformance} />

        <ProtectedRoute
          path="/dashboard/settings"
          component={DashboardSettings}
        />
        <Route path="/not-found" component={NotFound} />
        <Redirect from="/" exact to="/dashboard/home" />
        <Redirect to="/not-found" />
      </Switch>

      <Version style={{ textAlign: 'right' }}>
        {state.user.isAdmin ? (
          'Video Assistant version: ' + projectVersion
        ) : (
          ''
        )}</Version>
    </div>
  );
}

export default App;

const Version = styled.p`
  position: absolute;
  left: 0;
  bottom: 0;
  margin: 10px 100px;
  font-family: "montserrat";
  font-weight: 300;
  font-size: 12px;
  color: gray;

  @media (max-width: ${deviceSize.mobile}px) {
    display: none;
  }
`;

const StyledClipLoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
`;
