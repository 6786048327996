import React from "react";
import styled from "styled-components";
import { Tooltip } from "@material-ui/core";

function SnapEditorTooltip({ tooltipTitle, childElement }) {
  return (
    <StyledTooltip title={tooltipTitle} placement="right">
      {childElement}
    </StyledTooltip>
  );
}

export default SnapEditorTooltip;

const StyledTooltip = styled((props) => (
  <Tooltip classes={{ popper: props.className }} {...props} />
))`
  & .MuiTooltip-tooltip {
    background-color: #222433;
    color: #ffffff;
    font-size: 13px;
    font-family: "montserrat";
    padding: 5px 15px;
    font-weight: 300;
    border-radius: 6px;
  }
`;
