import React, { useEffect, useState, useContext } from "react";
import MaterialTable from '@material-table/core';
import styled from "styled-components";
import { store } from "../context/store";
import SnackbarCustomized from "./common/Snackbar";
import { getAllCompanies, deleteCompany } from "../services/companyService";

function SettingsTableCompanies({ onCompanyCreateUpdateForm }) {
  const { state, dispatch } = useContext(store);
  const [tableData, setTableData] = useState();
  const [isLoading, setLoading] = useState(true);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("#caf0f8");
  const [snackbarBgColor, setSnackbarBgColor] = useState("");

  const columns = [
    { title: "Domain", field: "domain" },
    { title: "Name", field: "name" },
    { title: "Subscription", field: "subscription" },
    { title: "Max subscriptions", field: "maxNumberOfSubscriptions" },
    { title: "Base url", field: "baseUrl" },
    { title: "Text message", field: "textMessage" },
  ];

  useEffect(async () => {
    const response = await getAllCompanies();
    if (response && response.status === 200) {
      setTableData(response.data);
      setLoading(false);
    }
  }, []);

  if (isLoading) {
    return <div></div>;
  }

  const handleDeleteRow = async (companyId) => {
    const data = {
      companyId: companyId,
    };
    const response = await deleteCompany(data);
    if (response && response.status === 204) {
      const originalTableData = [...tableData];
      const newTableData = originalTableData.filter(
        (td) => td.id !== companyId
      );
      setTableData(newTableData);
    } else {
      setOpenSnackbar(true);
      setSnackbarMessage(response);
      setSnackbarBgColor("#ff8484");
    }
  };

  const closeSnackbar = () => {
    setOpenSnackbar(false);
  };


  return (
    <Container>
      <SnackbarCustomized
        onOpen={openSnackbar}
        onCloseSnackbarParent={closeSnackbar}
        message={snackbarMessage}
        backgroundcolor={snackbarBgColor}
        textcolor={"#495057"}
      />
      <StyledMuiTable
        columns={columns}
        data={tableData}
        parentChildData={(row, rows) => rows.find((a) => a.id === row.parentId)}
        title="User table"
        actions={[
          {
            icon: "create",
            tooltip: "Update",
            onClick: (event, rowData) => {
              onCompanyCreateUpdateForm(rowData.id);
            },
          },
          {
            icon: "delete",
            tooltip: "Delete",
            onClick: (event, rowData) => {
              handleDeleteRow(rowData.id);
            },
            // confirm("You want to delete " + rowData.name),
          },
        ]}
        options={{
          actionsColumnIndex: -1, // Change action buttons to right side
          showTitle: false,
        }}
        localization={{
          header: {
            actions: "",
          },
        }}
      />
    </Container>
  );
}

export default SettingsTableCompanies;

const Container = styled.div`
  margin-top: 20px;
  display: block;

  & .MuiPaper-root {
    box-shadow: none !important;
  }

  & .MuiTableCell-head > span > div {
    font-family: "Comfortaa";
    font-size: 14px;
    font-weight: 600;
  }

  & .MuiTableCell-body {
    font-family: "Comfortaa" !important;
    font-size: 13px !important;
    font-weight: 300 !important;
  }

  & .MuiIconButton-root {
    :hover {
      background-color: transparent !important;
    }
  }

  & .MuiIcon-root {
    color: lightgray;
    font-size: 20px;
  }
`;

const StyledMuiTable = styled(MaterialTable)``;
