import React from "react";
import styled from "styled-components";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { deviceSize } from "../common/Breakpoints";

export default function Layout({ children, headerTitle }) {
  return (
    <>
      <MainContainer>
        <Sidebar />
        <HeaderContainer>
          <Header headerTitle={headerTitle} />
          <ContentContainer>{children}</ContentContainer>
        </HeaderContainer>
      </MainContainer>
    </>
  );
}

const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
`;

const HeaderContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 75px;

  @media (max-width: ${deviceSize.mobile - 1}px) {
    margin-left: 0px;
  }
`;

const ContentContainer = styled.div`
  flex: 1;
  width: 100%;
  overflow: scroll;
  padding-top: 75px; // for header
  background-color: #f6f9ff;

  /* Not display scroll bar with overflow */
  ::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* firefox */
`;
