import React from "react";
import styled from "styled-components";
import axios from "axios";

import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import SettingsTabs from "../components/SettingsTabs";
import { deviceSize } from "../components/common/Breakpoints";
import Layout from "../components/layout/Layout";

function DashboardSettings() {
  return (
    <Layout headerTitle="Settings">
        <ContentContainer>
            <ContentSettingsContainer>
              <SettingsTabs />
            </ContentSettingsContainer>
        </ContentContainer>
    </Layout>
  );
}

export default DashboardSettings;


const ContentContainer = styled.div`
  margin-top: 55px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  @media (max-width: ${deviceSize.tablet}px) {
    margin-left: 0px;
  }
`;

const ContentSettingsContainer = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 10px;
  background-color: white;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-bottom: 50px;
  @media (max-width:900px) {
    width:600px;
    margin-left: 0px;
  }
  @media (max-width: 768px) {
    width:500px;
    margin-left: 0px;
  }
  @media (max-width: 600px) {
    width:300px;
    margin-left: 0px;
  }
  @media (max-width: 426px) {
    width:300px;
    margin-left: 0px;
  }
`;
