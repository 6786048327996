import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { store } from "../context/store";

import SnackbarCustomized from "./common/Snackbar";
import { getMyUserInfo, updateMyUserInfo } from "../services/userService";
import { deviceSize } from "./common/Breakpoints";

function SettingsFormMyUser() {
  const { state, dispatch } = useContext(store);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("#caf0f8");
  const [snackbarBgColor, setSnackbarBgColor] = useState("");

  useEffect(() => {
    state.user.firstName && setFirstName(state.user.firstName);
    state.user.lastName && setLastName(state.user.lastName);
  }, [state.user]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await updateMyUserInfo(firstName, lastName);
    if (response && response.status === 200) {
      setOpenSnackbar(true);
      setSnackbarMessage("User info updated");
      setSnackbarBgColor("#caf0f8");
      dispatch({
        type: "UPDATE_USER",
        payload: { firstName, lastName },
      });
    } else {
      setOpenSnackbar(true);
      setSnackbarMessage(response);
      setSnackbarBgColor("#ff8484");
    }
  };

  const closeSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <Container>
      <SnackbarCustomized
        onOpen={openSnackbar}
        onCloseSnackbarParent={closeSnackbar}
        message={snackbarMessage}
        backgroundcolor={snackbarBgColor}
        textcolor={"#495057"}
      />
      <StyledFormTitle>Account settings</StyledFormTitle>
      <StyledForm
        onSubmit={(e) => {
          handleSubmit(e);
        }}
      >
        <StyledLabel htmlFor="firstName">First name:</StyledLabel>
        <br />
        <StyledTextInput
          id="firstName"
          name="firstName"
          type="text"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
        <br />
        <StyledLabel htmlFor="lastName">Last name:</StyledLabel>
        <br />
        <StyledTextInput
          id="lastName"
          name="lastName"
          type="text"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
        <StyledSubmitContainer>
          <StyledSubmit type="submit" value="Save" />
        </StyledSubmitContainer>
      </StyledForm>
    </Container>
  );
}

export default SettingsFormMyUser;

const Container = styled.div`
  width: 100%;
  /* max-width: 800px; */
  display: flex;
  flex-direction: column;
  /* overflow: scroll; */
  justify-content: flex-start;
  align-items: flex-start;
  /* margin-left: 25px; */
  border-radius: 10px;
  background-color: white;
  padding-left: 30px;
  padding-top: 15px;
  padding-bottom: 15px;
  @media (max-width: ${deviceSize.tablet}px) {
    padding-left: 0px;
  }
`;

const StyledFormTitle = styled.h2`
  font-family: "Comfortaa";
  font-size: 16px;
  color: #77727a;
  font-weight: 300;
  margin-bottom: 10px;
`;

const StyledForm = styled.form`
  width: 100%;
`;

const StyledLabel = styled.label`
  font-family: "Comfortaa";
  font-size: 11px;
  color: #77727a;
  font-weight: 300;
  position: relative;
  top: 9px;
  left: 19px;
  background-color: white;
`;

const StyledTextInput = styled.input`
  width: 50%;
  height: 40px;
  min-width: 250px;
  max-width: 500px;
  font-family: "Comfortaa";
  flex: 1;
  outline: 0;
  border: 1px solid whitesmoke;
  border-radius: 8px;
  background-color: white;
  padding: 0 12px;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0.5px;
  color: #303030;
  /* text-transform: uppercase; */
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px; */
  @media (max-width: ${deviceSize.tablet}px) {
    min-width: 200px;
  }
  @media (max-width: ${deviceSize.mobile}px) {
    min-width: 150px;
  }
`;

const StyledTextArea = styled.textarea``;

const StyledFieldSet = styled.fieldset``;

const StyledSubmitContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 30px;
  margin-right: 50px;
  margin-bottom: 30px;
`;

const StyledSubmit = styled.input`
  border-radius: 4px;
  text-decoration: none;
  border: none;
  text-align: center;
  font-family: "Comfortaa";
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.5px;
  padding: 13px 13px;
  background-color: #04a1c4;
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;

  :hover {
    opacity: 0.7;
  }
  :active {
    transform: scale(1.05);
    box-shadow: rgba(0, 0, 0, 0.18) 2px 6px 10px;
  }
`;
