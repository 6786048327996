import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { Avatar } from "@material-ui/core";
import LanguagePopover from "./LanguagePopover";
import { deviceSize } from "./common/Breakpoints";
import { store } from "../context/store";

function Header({ headerTitle }) {
  const { state, dispatch } = useContext(store);
  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [lastName, setLastName] = useState("");
  const [avatarUrl, setAvatarUrl] = useState(""); // "https://pbs.twimg.com/profile_images/1356697986835898371/t1r1URWC.jpg"

  useEffect(async () => {
    try {
      setFirstName(state.user.firstName || "");
      setLastName(state.user.lastName || "");
      setEmail(state.user.email || "");
    } catch (err) {
      console.log(err);
    }
  }, [state]);

  return (
    <HeaderContainer>
      <HeaderLeft>
        <HeaderLogoContainerMobile>
          <HeaderLogoMobile
            src={`${process.env.PUBLIC_URL}/assets/images/logo_pegamento.png`}
            alt=""
          />
        </HeaderLogoContainerMobile>
        <h1>{headerTitle}</h1>
      </HeaderLeft>
      <HeaderRight>
        <LanguagePopover />

        {avatarUrl ? (
          <HeaderAvatar src={avatarUrl} />
        ) : (
          <HeaderAvatar>{email.charAt(0)}</HeaderAvatar>
        )}
      </HeaderRight>
    </HeaderContainer>
  );
}

export default Header;

const HeaderContainer = styled.div`
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  border-bottom: 2px solid #f2f2f2;
  background-color: white;
  
  padding-top: 10px;
  padding-bottom: 10px;
  z-index: 99;
  height: 55px;
`;

const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  padding-left: 75px;
  @media (max-width: ${deviceSize.mobile}px) {
    padding-left: 0;
    }
  & > h1 {
    margin-left: 30px;
    font-family: "Comfortaa";
    font-size: 22px;
    color: #77727a;
    font-weight: 300;
    @media (max-width: ${deviceSize.mobile}px) {
      display: none;
    }
  }
`;

const HeaderRight = styled.div`
  display: flex;
  align-items: center;
  margin-right: 25px;
`;

const HeaderAvatar = styled(Avatar)`
  &&& {
    object-fit: contain;
    height: 35px;
    width: 35px;
    background-color: #04a1c4;
    text-transform: uppercase;
    /* :hover {
      opacity: 0.8;
    } */
  }
`;

const HeaderLogoContainerMobile = styled.div`
  display: none;
  @media (max-width: ${deviceSize.mobile}px) {
    display: block;
    height: 35px;
  }
`;

const HeaderLogoMobile = styled.img`
  object-fit: contain; // keeps aspect ratio
  height: 35px;
  margin-left: 18px;
`;
