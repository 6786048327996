import React, { useState } from "react";
import Popover from "@material-ui/core/Popover";
import Button from "@material-ui/core/Button";
import styled from "styled-components";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

export default function LanguagePopover() {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <LanguageContainer aria-describedby={id} onClick={handleClick}>
        <LanguageFlagIcon
          alt="English"
          src={`${process.env.PUBLIC_URL}/assets/icons/flag_GB.svg`}
        />
        <ExpandMoreIcon />
      </LanguageContainer>
      <StyledPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <LanguagePopoverContainer onClick={handleClose}>
          <LanguageFlagIcon
            alt="English"
            src={`${process.env.PUBLIC_URL}/assets/icons/flag_GB.svg`}
          />
        </LanguagePopoverContainer>
      </StyledPopover>
    </div>
  );
}

const StyledPopover = styled(Popover)`
  margin-top: 10px;
`;

const LanguageContainer = styled.div`
  width: 30px;
  height: 30px;
  margin-right: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  cursor: pointer;

  & .MuiSvgIcon-root {
    padding-top: 4px;
    margin-left: -2px;
    font-size: 19px;
  }
`;

const LanguageFlagIcon = styled.img`
  width: 20px;
`;

const LanguagePopoverContainer = styled.div`
  margin: 15px 5px;
  width: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
