import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { fabric } from "fabric";
import { getDetectionsForImage } from "../services/userService";
import { store } from "../context/store";

import { deviceSize } from "./common/Breakpoints";
import { useWindowSize } from "../hooks/windowSize";
import SnapEditorTooltip from "./SnapEditorTooltip";
import SnapEditorPopover from "./SnapEditorPopover";
import { Tooltip } from "@material-ui/core";
import LoadingSpinner from "./LoadingSpinner";

import SendIcon from "@material-ui/icons/Send";
import GetAppIcon from '@material-ui/icons/GetApp';
import PhotoFilter from "@material-ui/icons/PhotoFilter";
import CropSquareIcon from "@material-ui/icons/CropSquare";
import ClearIcon from "@material-ui/icons/Clear";
import FiberManualRecordOutlinedIcon from "@material-ui/icons/FiberManualRecordOutlined";
import LineWeightIcon from "@material-ui/icons/LineWeight";
import CategoryOutlinedIcon from "@material-ui/icons/CategoryOutlined";
import BorderColorOutlinedIcon from "@material-ui/icons/BorderColorOutlined";
import FormatShapesOutlinedIcon from "@material-ui/icons/FormatShapesOutlined";
import FormatColorTextOutlinedIcon from "@material-ui/icons/FormatColorTextOutlined";
import FormatSizeOutlinedIcon from "@material-ui/icons/FormatSizeOutlined";
import ReplyIcon from "@material-ui/icons/Reply";
import CameraAltIcon from "@material-ui/icons/CameraAlt";

function SnapEditor({
  onSendSnapshot,
  aspectRatio,
  snapshot,
  refreshEditor,
}) {
  const size = useWindowSize();
  const [canvas, setCanvas] = useState(null);
  const [snapActive, setSnapActive] = useState(false);
  const [objectActive, setObjectActive] = useState(false);
  const [objectColor, setObjectColor] = useState("#888888");
  const [fontColor, setFontColor] = useState("#888888");
  const [isLoading, setIsLoading] = useState(false);
  const { state, dispatch } = useContext(store);

  useEffect(() => {
    setCanvas(initCanvas());
  }, []);

  const initCanvas = () =>
    new fabric.Canvas("canvas", {
      width: 400,
      height: 400 / aspectRatio,
      backgroundColor: "white",
    });

  // if no videoheight and width,set based width & height on current view as %.
  // if videoheight and width, set the height based on the aspect ratio

  const newResizeCanvas = (canvi) => {
    let width;
    let height;
    if (size.width && size.height !== null) {
      if (aspectRatio >= 1) {
        if (size.width >= 768) {
          width = size.width * 0.3 > 500 ? 500 : size.width * 0.3;
          height = width / aspectRatio;
        } else if (size.width >= 550 && size.width < 768) {
          width = size.width * 0.5;
          height = width / aspectRatio;
        } else if (size.width >= 426 && size.width < 550) {
          width = size.width * 0.6;
          height = width / aspectRatio;
        } else {
          width = size.width * 0.7;
          height = width / aspectRatio;
        }
      } else {
        if (size.width >= 768) {
          width = size.width * 0.3 > 400 ? 300 : size.width * 0.3;
          height = width / aspectRatio;
        } else if (size.width >= 550 && size.width < 768) {
          width = size.width * 0.4;
          height = width / aspectRatio;
        } else if (size.width >= 420 && size.width < 550) {
          width = size.width * 0.55;
          height = width / aspectRatio;
        } else {
          width = size.width * 0.7;
          height = width / aspectRatio;
        }
      }
      canvi.setHeight(height);
      canvi.setWidth(width);
      canvi.calcOffset();
      canvi.renderAll();
    }
  };

  useEffect(() => {
    newResizeCanvas(canvas);
  }, [size.width, aspectRatio]);

  const addShape = (canvi, shape) => {
    setObjectActive(true);
    if (shape === "rect") {
      const rect = new fabric.Rect({
        height: 100,
        width: 100,
        fill: "",
        stroke: "darkgray",
        strokeWidth: 5,
      });
      canvi.add(rect);
      canvi.hoverCursor = "pointer";
      canvi.renderAll();
    } else if (shape === "circle") {
      const circle = new fabric.Circle({
        radius: 50,
        fill: "",
        stroke: "darkgray",
        strokeWidth: 5,
      });
      canvi.add(circle);
      canvi.hoverCursor = "pointer";
      canvi.renderAll();
    }
  };

  const addText = (canvi) => {
    setObjectActive(true);
    const textItem = new fabric.IText("Type here", {
      left: 50,
      top: 100,
      fontFamily: "montserrat",
      fill: "#333",
      fontSize: 20,
    });
    canvi.add(textItem);
    canvi.hoverCursor = "pointer";
    canvi.renderAll();
  };

  const addSnapBackground = () => {
    if (snapshot !== null) {
      setSnapActive(true);
      canvas.clear();
      fabric.Image.fromURL(snapshot, function (img) {
        canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas), {
          scaleX: canvas.width / img.width,
          scaleY: canvas.height / img.height,
        });
      });
    }
  };

  useEffect(() => {
    addSnapBackground();
  }, [snapshot]);

  const deleteObject = (canvi) => {
    // listen for delete key
    try {
      let activeObject = canvi.getActiveObject();
      if (activeObject) {
        canvi.remove(activeObject);
        canvi.renderAll();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const deleteAll = (canvi) => {
    // listen for delete key
    try {
      setObjectActive(false);
      setSnapActive(false);
      canvi.clear();
      canvi.backgroundColor = "#fff";
      canvi.renderAll();
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (refreshEditor === true) {
      deleteAll(canvas);
    }
  }, [refreshEditor]);

  const sendSnapshotToPeer = (canvi) => {
    let snapShotUrl = canvi.toDataURL("image/png");
    onSendSnapshot(snapShotUrl);
  };

  const changeObjectColor = (canvi) => {
    // get the color value and add to active object
    try {
      if (objectActive) {
        let activeObject = canvi.getActiveObject();
        if (activeObject.get("type") !== "i-text") {
          activeObject.set("stroke", objectColor);
        }

        canvi.renderAll();
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    changeObjectColor(canvas);
  }, [objectColor]);

  const changeFontColor = (canvi) => {
    // get the color value and add to active object
    try {
      if (objectActive) {
        let activeObject = canvi.getActiveObject();

        if (activeObject.get("type") === "i-text") {
          activeObject.set("fill", fontColor);
        }
        canvi.renderAll();
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    changeFontColor(canvas);
  }, [fontColor]);

  const changeStroke = (canvi, strokeValue) => {
    try {
      let activeObject = canvi.getActiveObject();
      if (activeObject.get("type") !== "i-text") {
        activeObject.set("strokeWidth", strokeValue);
      }
      canvi.renderAll();
    } catch (err) {
      console.log(err);
    }
  };

  const changeFontsize = (canvi, fontSizeValue) => {
    try {
      let activeObject = canvi.getActiveObject();
      if (activeObject) {
        // console.log(activeObject.get("type"))
        if (activeObject.get("type") === "i-text") {
          activeObject.set("fontSize", fontSizeValue);
        }
        canvi.renderAll();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const downloadSnapshot = (canvi) => {
    try {
      let snapShotUrl = canvi.toDataURL("image/png");
      var link = document.createElement('a');
      link.download = 'snapshot.png';
      link.href = snapShotUrl;
      link.click();
    } catch (err) {
      console.log(err);
    }
  }

  const fetchObjectDetection = async () => {
    canvas.getElement().toBlob(async function (blob) {
      setIsLoading(true);
      try {
        const response = await getDetectionsForImage(blob)
        const imageObjectURL = URL.createObjectURL(response.data)
        canvas.clear();
        fabric.Image.fromURL(imageObjectURL, function (img) {
          canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas), {
            scaleX: canvas.width / img.width,
            scaleY: canvas.height / img.height,
          })
        })
      }
      finally {
        setIsLoading(false);
      }
    });
  }

  return (
    <Container>
      <CanvasContainer>
        <Canvas id="canvas" />
        <StyledTooltip title={"Clear snapshot"} placement="right">
          <StyledRoundedInSnapshotButton
            btnMargin={"15px 15px 0 0"}
            bgColor={"#2a2a2a"}
            btnTop={"0"}
            btnRight={"0"}
            onClick={() => deleteAll(canvas)}
          >
            <ClearIcon />
          </StyledRoundedInSnapshotButton>
        </StyledTooltip>
        <StyledTooltip title={"Send snapshot"} placement="right">
          <StyledRoundedInSnapshotButton
            btnMargin={"55px 15px 0 0"}
            bgColor={"#2a2a2a"}
            btnTop={"0"}
            btnRight={"0"}
            onClick={() => sendSnapshotToPeer(canvas)}
          >
            <SendIcon />
          </StyledRoundedInSnapshotButton>
        </StyledTooltip>
        {snapshot !== null &&
          <StyledTooltip title={"Download snapshot"} placement="right">
            <StyledRoundedInSnapshotButton
              btnMargin={"95px 15px 0 0"}
              bgColor={"#2a2a2a"}
              btnTop={"0"}
              btnRight={"0"}
              onClick={() => downloadSnapshot(canvas)}
            >
              <GetAppIcon />
            </StyledRoundedInSnapshotButton>
          </StyledTooltip>
        }
        {snapshot !== null && state.user && state.user.company.imageRecognitionActive &&
          <StyledTooltip title={"Image recognition"} placement="right">
            <StyledRoundedInSnapshotButton
              btnMargin={"135px 15px 0 0"}
              bgColor={"#2a2a2a"}
              btnTop={"0"}
              btnRight={"0"}
              onClick={() => fetchObjectDetection()}
            >
              <PhotoFilter />
            </StyledRoundedInSnapshotButton>
          </StyledTooltip>
        }

        {isLoading ?
          <StyledLoadingContainer
            btnMargin={"155px 35px 0 0"}
          >
            <LoadingSpinner />
          </StyledLoadingContainer> : ""}

        {!snapActive && !objectActive && (
          <StyledInSnapBgPicture>
            <CameraAltIcon />
          </StyledInSnapBgPicture>
        )}
      </CanvasContainer>


      <SnapshotTools>
        <SnapshotSubMenu>
          <SnapEditorPopover
            tooltipTitle={"Add shapes"}
            expandBool={true}
            iconSize={"20px"}
            iconMargin={"0 -4px 0 -1px"}
            canvi={canvas}
            onChange={addShape}
            Icon={CategoryOutlinedIcon}
            IconPopupTop={FiberManualRecordOutlinedIcon}
            IconPopupMiddle={CropSquareIcon}
            valueTop={"circle"}
            valueMiddle={"rect"}
          />
          <SnapEditorTooltip
            tooltipTitle={"Line color"}
            childElement={
              <StyledColorButton>
                <BorderColorOutlinedIcon />
                <ColorInput
                  id="objectColor"
                  type="color"
                  value={objectColor}
                  onChange={(e) => setObjectColor(e.target.value)}
                />
              </StyledColorButton>
            }
          />
          <SnapEditorPopover
            tooltipTitle={"Line width"}
            canvi={canvas}
            onChange={changeStroke}
            Icon={LineWeightIcon}
            IconPopupTop={FiberManualRecordOutlinedIcon}
            iconSizeTop={"18px"}
            iconSizeMiddle={"14px"}
            iconSizeBottom={"10px"}
            IconPopupMiddle={FiberManualRecordOutlinedIcon}
            IconPopupBottom={FiberManualRecordOutlinedIcon}
            valueTop={9}
            valueMiddle={5}
            valueBottom={2}
          />
        </SnapshotSubMenu>
        <SnapshotSubMenu>
          <SnapEditorTooltip
            tooltipTitle={"Add text"}
            childElement={
              <StyledColorButton onClick={() => addText(canvas)}>
                <FormatShapesOutlinedIcon />
              </StyledColorButton>
            }
          />
          <SnapEditorTooltip
            tooltipTitle={"Text color"}
            childElement={
              <StyledColorButton>
                <FormatColorTextOutlinedIcon />
                <ColorInput
                  id="fontColor"
                  type="color"
                  value={fontColor}
                  onChange={(e) => setFontColor(e.target.value)}
                />
              </StyledColorButton>
            }
          />
          <SnapEditorPopover
            tooltipTitle={"Font size"}
            canvi={canvas}
            onChange={changeFontsize}
            Icon={FormatSizeOutlinedIcon}
            IconPopupTop={FiberManualRecordOutlinedIcon}
            iconSizeTop={"18px"}
            iconSizeMiddle={"14px"}
            iconSizeBottom={"10px"}
            IconPopupMiddle={FiberManualRecordOutlinedIcon}
            IconPopupBottom={FiberManualRecordOutlinedIcon}
            valueTop={54}
            valueMiddle={38}
            valueBottom={20}
          />
        </SnapshotSubMenu>
        <SnapEditorTooltip
          tooltipTitle={"Delete item"}
          childElement={
            <StyledColorButton onClick={() => deleteObject(canvas)}>
              <ReplyIcon />
            </StyledColorButton>
          }
        />
      </SnapshotTools>

      {/* <StyledButton color="default" onClick={() => canvasToImage(canvas)}>
          <SendIcon />
        </StyledButton> */}
    </Container>
  );
}

export default SnapEditor;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  /* margin-bottom: 50px; */
  @media (max-width: ${deviceSize.tablet - 1}px) {
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

const CanvasContainer = styled.div`
  position: relative;
`;

const StyledInSnapBgPicture = styled.div`
  position: absolute;
  left: 50%;
  margin-left: -40px;
  top: 50%;
  margin-top: -40px;
  & .MuiSvgIcon-root {
    font-size: 80px;
    color: lightgray;
    @media (max-width: ${deviceSize.tablet - 1}px) {
      font-size: 60px;
    }
  }
`;

const Canvas = styled.canvas`
  border-radius: 20px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  /* border: 4px solid whitesmoke; */
`;

const SnapshotTools = styled.div`
  width: 50px;
  margin-left: 30px;
  background-color: #fff;
  z-index: 1000;
  border-radius: 8px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: ${deviceSize.tablet - 1}px) {
    margin-left: 20px;
  }
`;

const SnapshotSubMenu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  list-style: none;
  width: 60%;
  padding: 5px 0;
  border-bottom: 2px solid #f2f2f2;
`;

const StyledButton = styled.div`
  display: flex;
  width: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  margin: 8px 0;
  :hover {
    opacity: 0.7;
  }
  :active {
    transform: scale(1.05);
  }
  & .MuiSvgIcon-root {
    font-size: ${(props) => props.iconSize || "24px"};
    color: ${(props) => props.iconColor || "#a6acb4"};
    margin: ${(props) => props.iconMargin || "0px"};
  }
`;

const StyledRoundedInSnapshotButton = styled.div`
  display: flex;
  height: 30px;
  width: 30px;
  border-radius: 100px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  position: absolute;
  top: ${(props) => props.btnTop || ""};
  right: ${(props) => props.btnRight || ""};
  bottom: ${(props) => props.btnBottom || ""};
  left: ${(props) => props.btnLeft || ""};
  z-index: 1000;
  margin: ${(props) => props.btnMargin || "0px"};
  background-color: ${(props) => props.bgColor || "#04a1c4"};
  opacity: 0.7;
  :hover {
    opacity: 0.8;
  }
  :active {
    transform: scale(1.05);
    box-shadow: rgba(0, 0, 0, 0.18) 2px 6px 10px;
  }
  & .MuiSvgIcon-root {
    font-size: 18px;
    color: ${(props) => props.iconColor || "#fff"};
  }
`;


const StyledLoadingContainer = styled.div`
  display: flex;
  height: 30px;
  width: 30px;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  position: absolute;
  top: ${(props) => props.btnTop || ""};
  right: ${(props) => props.btnRight || ""};
  bottom: ${(props) => props.btnBottom || ""};
  left: ${(props) => props.btnLeft || ""};
  z-index: 1000;
  margin: ${(props) => props.btnMargin || "0px"};
  & .MuiSvgIcon-root {
    font-size: 18px;
    color: ${(props) => props.iconColor || "#fff"};
  }
`;

const StyledColorButton = styled(StyledButton)`
  position: relative;
`;

const ColorInput = styled.input`
  position: absolute;
  opacity: 0;
  display: block;
  width: 100%;
  height: 100%;
  border: none;
  z-index: 100;
  cursor: pointer;
`;

const StyledInVideoOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledInVideoBgPicture = styled.div`
  & .MuiSvgIcon-root {
    font-size: 80px;
    color: lightgray;
  }
`;

const StyledTooltip = styled((props) => (
  <Tooltip classes={{ popper: props.className }} {...props} />
))`
  & .MuiTooltip-tooltip {
    background-color: #222433;
    color: #fff;
    font-size: 13px;
    font-family: "montserrat";
    padding: 5px 15px;
    font-weight: 300;
    border-radius: 6px;
  }
`;
