import axios from "axios";

axios.defaults.withCredentials = true;

export async function createChatLog(data) {
  try {
    const response = await axios.post("/api/chat/create", data);
    return response;
  } catch (err) {
    return err.response.data.message;
  }
}
