import React from "react";
import styled from "styled-components";
import { IconButton } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";

function SnapDisplay({ image, onToggle }) {
  return (
    <Container>
      <SnapContainer>
        <StyledIconButton onClick={onToggle}>
          <ClearIcon />
        </StyledIconButton>
        <Snapshot src={image} />
      </SnapContainer>
    </Container>
  );
}

export default SnapDisplay;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  /* overflow: hidden;
  object-fit: cover; */
`;

const SnapContainer = styled.div`
  position: relative;
  padding: 0 20px;
`;

const StyledIconButton = styled(IconButton)`
  &&& {
    position: absolute;
    top: 0;
    color: white;
    background-color: rgba(0, 0, 0, 0.3);
    margin: 10px;
  }
`;

const Snapshot = styled.img`
  border-radius: 15px;
  /* border: 3px solid whitesmoke; */
  width: 100%;
  max-height: 100%;
  box-shadow: 2px 2px 3px 2px rgba(0, 0, 0, 0.5);
`;
