import axios from "axios";

axios.defaults.withCredentials = true;

export async function sendMessage(data) {
  try {
    const response = await axios.post("/api/message/send", data);
    return response;
  } catch (err) {
    console.log(err)
    return err.response.data.message;
  }
}
