import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Popover } from "@material-ui/core";
import SnapEditorTooltip from "./SnapEditorTooltip";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

export default function SnapEditorPopover({
  tooltipTitle,
  expandBool,
  iconSize,
  iconMargin,
  Icon,
  canvi,
  onChange,
  IconPopupTop,
  IconPopupMiddle,
  IconPopupBottom,
  iconSizeTop,
  iconSizeMiddle,
  iconSizeBottom,
  valueTop,
  valueMiddle,
  valueBottom,
  inputBool,
  min,
  max,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [inputValue, setInputValue] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleInputValue = () => {
    if (inputBool && inputValue && inputValue !== null && inputValue !== 0 && inputValue >= min && inputValue <= max ) {
      onChange(canvi, Number(inputValue));
    }
  };

  useEffect(() => {
    handleInputValue();
  }, [inputValue]);

  const open = Boolean(anchorEl);
  const id = open ? "popover" : undefined;

  return (
    <div>
      <SnapEditorTooltip
        tooltipTitle={tooltipTitle}
        childElement={
          <StyledButton
            color="default"
            ria-describedby={id}
            onClick={handleClick}
            iconSize={iconSize}
            iconMargin={iconMargin}
          >
            <Icon />
            {expandBool && <ExpandMoreIcon />}
          </StyledButton>
        }
      />
      <StyledPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
      >
        <ButtonContainer>
          {IconPopupTop && (
            <StyledButton onClick={() => onChange(canvi, valueTop)} iconSize={iconSizeTop}>
              <IconPopupTop />
            </StyledButton>
          )}
          {IconPopupMiddle && (
            <StyledButton onClick={() => onChange(canvi, valueMiddle)} iconSize={iconSizeMiddle}>
              <IconPopupMiddle />
            </StyledButton>
          )}
          {IconPopupBottom && (
            <StyledButton onClick={() => onChange(canvi, valueBottom)} iconSize={iconSizeBottom}>
              <IconPopupBottom />
            </StyledButton>
          )}
          {inputBool && (
            <StyledInput
              type="number"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
            />
          )}
        </ButtonContainer>
      </StyledPopover>
    </div>
  );
}

const StyledPopover = styled(Popover)`
  margin-left: 12px;

  & .MuiPaper-elevation8 {
    width: 40px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
      rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledButton = styled.div`
  display: flex;
  /* height: 50px; */
  width: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  margin: 8px 0;
  :hover {
    opacity: 0.7;
  }
  :active {
    transform: scale(1.05);
  }
  & .MuiSvgIcon-root {
    font-size: ${(props) => props.iconSize || "24px"};
    color: ${(props) => props.iconColor || "#a6acb4"};
    margin: ${(props) => props.iconMargin || "0px"};
  }
`;

const StyledInput = styled.input`
  display: block;
  width: 100%;
  height: 35px;
  font-size: 14px;
  font-family: "montserrat";
  font-weight: 600;
  text-align: center;
  border: none;
  line-height: 1.65;
  background-color: transparent;
  /* ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  } */

  ::-webkit-inner-spin-button {
    border-radius: none !important;
    color: red;
    opacity: 0.8; /* shows Spin Buttons per default (Chrome >= 39) */
  }
  :focus {
    outline: 0;
  }
`;
