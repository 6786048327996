import React, { useContext } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import SidebarOption from "./SidebarOption";
import auth from "../services/authService";
// import { logout } from "../features/userSlice";
import { deviceSize } from "./common/Breakpoints";
import { store } from "../context/store";
import { useCookies } from "react-cookie";

// Icons
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import ExitToAppRoundedIcon from "@material-ui/icons/ExitToAppRounded";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import ContactSupportOutlinedIcon from "@material-ui/icons/ContactSupportOutlined";
import AssessmentOutlinedIcon from "@material-ui/icons/AssessmentOutlined";
import ChromeReaderModeOutlinedIcon from "@material-ui/icons/ChromeReaderModeOutlined";

function Sidebar() {
  const history = useHistory();
  const { state, dispatch } = useContext(store);
  const [cookies, setCookie, removeCookie] = useCookies(["token"]);

  const logoutUser = async () => {
    console.log(state.loggedIn);
    const response = await auth.logout();
    console.log(response);
    dispatch({
      type: "LOGOUT", // The name of the reducer
    });
    removeCookie();
    console.log(state.loggedIn);
    history.push("/login");
  };

  return (
    <SidebarContainer>
      <SidebarLogoContainer>
        <SidebarLogo
          src={`${process.env.PUBLIC_URL}/assets/images/logo_pegamento.png`}
          alt=""
        />
      </SidebarLogoContainer>
      <SidebarMenu>
        <SidebarOption
          Icon={HomeOutlinedIcon}
          tooltipTitle="Home"
          path="/dashboard/home"
        />
      </SidebarMenu>
      <SidebarMenu>
        <SidebarOption
          Icon={ChromeReaderModeOutlinedIcon}
          tooltipTitle="Knowledge"
        />
        <SidebarOption
          Icon={AssessmentOutlinedIcon}
          tooltipTitle="Performance"
          path="/dashboard/performance"
        />
        <SidebarOption
          Icon={ContactSupportOutlinedIcon}
          tooltipTitle="Support"
        />
      </SidebarMenu>
      <SidebarMenu>
        <SidebarOption
          Icon={SettingsOutlinedIcon}
          tooltipTitle="Settings"
          path="/dashboard/settings"
        />
      </SidebarMenu>
      <SidebarLogout>
        <SidebarOption
          Icon={ExitToAppRoundedIcon}
          tooltipTitle="Logout"
          onClick={logoutUser}
        />
      </SidebarLogout>
    </SidebarContainer>
  );
}

export default Sidebar;

const SidebarContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  height: 100vh;
  width: 75px;
  left: 0;
  top: 0;
  z-index: 100;
  background-color: #fff; // #222433;
  border-right: 2px solid #f2f2f2; // whitesmoke
  @media (max-width: ${deviceSize.mobile}px) {
    display: none;
  }
`;

const SidebarLogoContainer = styled.div`
  width: 100%;
  height: 75px; // height of header
`;

const SidebarLogo = styled.img`
  object-fit: contain; // keeps aspect ratio
  height: 40px;
  margin-left: 18px;
  margin-top: 18px;
`;

const SidebarMenu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  list-style: none;
  width: 100%;
  padding: 10px 0;
  border-bottom: 2px solid #f2f2f2; // whitesmoke
  /* margin-top: 35px; */
`;

const SidebarLogout = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  list-style: none;
  width: 100%;
  margin-bottom: 25px;
`;
