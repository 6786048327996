import React, { useState, useEffect, useContext } from "react";
// import { login, logout, selectUser } from "../features/userSlice";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import styled from "styled-components";
import auth from "../services/authService";
import SnackbarCustomized from "./common/Snackbar";
import { store } from "../context/store";

function Login() {
  const { state, dispatch } = useContext(store);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [forgotPassword, setForgotPassword] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("Password updated");
  const [snackbarBgColor, setSnackbarBgColor] = useState("#caf0f8");

  const history = useHistory();

  const loginUser = async (e) => {
    e.preventDefault();
    try {
      const response = await auth.login(email, password);
      if (response.status === 200) {
        dispatch({
          type: "LOGIN", // The name of the reducer
          payload: response.data,
        });
        history.push("/");
      }
    } catch (err) {
      setError(err.response.data.message);
    }
  };

  const resetUser = async (e) => {
    e.preventDefault();
    try {
      const response = await auth.resetPassword(email);
      setForgotPassword(false)
      setOpenSnackbar(true);
      setSnackbarMessage("A new password is sent to your email");
      setSnackbarBgColor("#caf0f8");
    } catch (err) {
      setError(err.response.data.message);
    }
  };

  const closeSnackbar = () => {
    setOpenSnackbar(false);
  };


  // Prevent going to login form if already logged in
  if (state.authenticated) return <Redirect to="/" />;

  return (
    <Container>
      <SnackbarCustomized
        onOpen={openSnackbar}
        onCloseSnackbarParent={closeSnackbar}
        message={snackbarMessage}
        backgroundcolor={snackbarBgColor}
        textcolor={"#495057"}
      />
      <Logo src={`${process.env.PUBLIC_URL}/assets/images/logo_pegamento_text.jpg`} />
      <LoginForm>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        {!forgotPassword && (
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        )}
        {error && !forgotPassword && <ErrorSpan>{error}</ErrorSpan>}
        {!forgotPassword && (
          <button type="submit" onClick={loginUser}>
            Sign in
          </button>
        )}
        {forgotPassword && (
          <button type="submit" onClick={resetUser}>
            Reset password
          </button>
        )}
        {!forgotPassword ? (
          <p>
            <span onClick={() => setForgotPassword(true)}>
              Forgot password?
            </span>
          </p>
        ) : (
          <p>
            <span onClick={() => setForgotPassword(false)}>
              Go to login screen
            </span>
          </p>
        )}
      </LoginForm>
    </Container>
  );
}

export default Login;

const Container = styled.div`
  display: grid;
  place-items: center;
  margin: 0 auto;
  padding: 100px 0;
`;

const Logo = styled.img`
  object-fit: contain; // keep aspect ratio
  height: 100px;
  margin: 20px 0;
`;

const LoginForm = styled.form`
  display: flex;
  flex-direction: column;

  & > input {
    width: 350px;
    height: 50px;
    font-size: 20px;
    padding-left: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
  }

  & > button {
    width: 365px;
    height: 50px;
    font-size: large;
    color: #fff;
    background-color: #00a2c5;
    border-radius: 5px;
    border: 0;
    cursor: pointer;
  }

  & > p {
    margin-top: 20px;
  }

  & > p span {
    color: #0074b1;
    cursor: pointer;
  }
`;

const ErrorSpan = styled.span`
  font-family: "montserrat";
  font-style: italic;
  font-size: 12px;
  font-weight: 300;
  margin-bottom: 8px;
  color: #333;
`;
