import React from "react";
import styled from "styled-components";
import { Tooltip } from "@material-ui/core";
import { NavLink } from "react-router-dom";

// We pass a material UI icon so its capitalized
function SidebarOption({ Icon, tooltipTitle, path, onClick }) {
  return (
    <Container onClick={onClick}>
      {path ? (
        <StyledNavLink
          to={path}
          exact
          activeStyle={{
            color: "#04a1c4",
          }}
        >
          <StyledTooltip title={tooltipTitle} placement="right">
            {Icon && <Icon />}
          </StyledTooltip>
        </StyledNavLink>
      ) : (
        <StyledTooltip title={tooltipTitle} placement="right">
          {Icon && <Icon />}
        </StyledTooltip>
      )}
    </Container>
  );
}

export default SidebarOption;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 12px;
  color: #a6acb4;
  cursor: pointer;
  & :hover {
    color: #04a1c4;
  }
  & .MuiSvgIcon-root {
    font-size: 26px;
  }
`;

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  color: #a6acb4;
`;

// const StyledLink = styled(Link)`
//   text-decoration: none;
//   color: #a6acb4;
//   &:focus,
//   &:hover,
//   &:visited,
//   &:link,
//   &:active {
//     text-decoration: none;
//   }
// `;

const StyledTooltip = styled((props) => (
  <Tooltip classes={{ popper: props.className }} {...props} />
))`
  & .MuiTooltip-tooltip {
    background-color: #222433;
    color: #fff;
    font-size: 13px;
    font-family: "montserrat";
    padding: 5px 15px;
    font-weight: 300;
    border-radius: 6px;
  }
`;
