import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import styled from "styled-components";

import SocketContext from "../context/SocketContext";
import { initPeer } from "../services/initPeer";
import { getRoomValidity } from "../services/roomService";
import AcceptTerms from "../components/AcceptTerms";
import SnapDisplay from "../components/SnapDisplay";

import { IconButton } from "@material-ui/core";
import CallEndIcon from "@material-ui/icons/CallEnd";
import { useWindowSize } from "../hooks/windowSize";

import { createChatLog } from "../services/chatService";
import { closeRoom } from "../services/roomService";
import SnackbarCustomized from "../components/common/Snackbar";
import InvalidChat from "../components/InvalidChat";


function CustomerVideo() {
  const socket = useContext(SocketContext);
  // const myPeer = useContext(PeerContext);
  const [myPeer, setMyPeer] = useState(() => initPeer());
  const size = useWindowSize();
  const { peerId, roomId } = useParams(); // set the callId in the link
  const constraints = { video: { facingMode: "environment" }, audio: false };
  const [myId, setMyId] = useState(null);
  const [snapshot, setSnapshot] = useState(null);
  const [toggleSnapshot, setToggleSnapshot] = useState(false);
  const [acceptTerms, setacceptTerms] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("Snapshot taken");
  const [snackbarBgColor, setSnackbarBgColor] = useState("#caf0f8");
  const [companyID, setCompanyID] = useState(null);
  const [linkValid, setLinkValid] = useState(true);

  const userVideo = useRef(); // Reference video

  const history = useHistory();

  const sendError = (error) => {
    socket.emit("send-error", {
      error: error,
    });
  };

  // TODO: add check browser support: window.peerjs.util.supports.data

  // useEffect(() => {
  //   setLinkValid(true);
  // }, []);

  useEffect(() => {
    async function fetchLinkValidity() {
      let validLink = await getRoomValidity(roomId, peerId)
      if (!validLink.data.valid) {
        setLinkValid(false);
      }
    }

    myPeer.on("open", function (id) {
      setMyId(id);
    });

    fetchLinkValidity()

    if (linkValid) {
      socket.emit("join-room", roomId, myId);
      navigator.mediaDevices.getUserMedia(constraints).then(
        (stream) => {
          sendError(`my id: ${myId} ,peerId: ${peerId}, stream: ${stream}`);
          userVideo.current.srcObject = stream;
          const call = myPeer.call(peerId, stream);
          // call.on("stream", (remoteMediaStream) => {
          // partnerAudio.current.srcObject = remoteMediaStream;
          // });
          // const data = {
          //   room: roomId,
          //   agent: peerId,
          // };
          // const response = createChatLog(data); // TODO If we want this, either provide client with auth, or remove auth requirement from endpoint
          call.on("close", () => {
            // leaveCall(false)
          });
          call.on("error", function (err) {
            console.log(err);
            // sendError(err)
            // leaveCall();
          });
        },
        (err) => {
          console.log(err);
          sendError(err);
        }
      );
      // myPeer.on("close", () => {
      //   leaveCall();
      // });

      myPeer.on("error", function (err) {
        console.log(err);
        sendError(err);
        // leaveCall();
      });
    }
  }, [acceptTerms]);

  const handleAcceptTerms = () => {
    setacceptTerms(true);
    setLinkValid(true);
    socket.emit("accept-terms", {
      roomId: roomId,
      acceptTermsBool: true,
    });
  };

  const handleToggle = () => {
    setToggleSnapshot(false);
  };

  socket.on("company-id", (id) => {
    setCompanyID(id);
  });

  socket.on("display-snapshot", (image) => {
    setSnapshot(image);
    setToggleSnapshot(true);
  });

  socket.on("take-snapshot", (message) => {
    setOpenSnackbar(true);
    setSnackbarMessage(message);
    setSnackbarBgColor("#caf0f8");
  });

  const closeSnackbar = () => {
    setOpenSnackbar(false);
  };

  socket.on("call-ended", (callEndedBool) => {
    if (callEndedBool) {
      try {
        const data = {
          room_id: roomId
        }
        closeRoom(data)
        if (myPeer) {
          myPeer.disconnect();
          myPeer.destroy();
        }
        if (userVideo.current.srcObject !== null) {
          userVideo.current.srcObject.getTracks().forEach((track) => {
            track.stop();
          });
        }
        history.replace("/callended");
        socket.disconnect();
      } catch (err) {
        console.log(err);
      }
    }
  });

  const leaveCall = () => {
    try {
      myPeer.disconnect();
      myPeer.destroy();
      userVideo.current.srcObject.getTracks().forEach((track) => {
        track.stop();
      });
      socket.emit("call-ended", {
        roomId: roomId,
        callEndedBool: true,
      });
      history.replace("/callended");
      socket.disconnect();

    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Container containerHeight={size.height} containerWidth={size.width}>
      <SnackbarCustomized
        onOpen={openSnackbar}
        onCloseSnackbarParent={closeSnackbar}
        message={snackbarMessage}
        backgroundcolor={snackbarBgColor}
        autoHideDuration={5000}
        textcolor={"#495057"}
      />
      {!acceptTerms && linkValid && <AcceptTerms onClick={handleAcceptTerms} companyID={companyID}/>}
      {!linkValid && <InvalidChat />}
      <VideoContainer>
        <Footer>
          <EndCallButton onClick={leaveCall}>
            <CallEndIcon />
          </EndCallButton>
        </Footer>
        <StyledVideo ref={userVideo} autoPlay playsInline muted />
        {snapshot && toggleSnapshot && (
          <Overlay>
            <SnapDisplay image={snapshot} onToggle={handleToggle} />
          </Overlay>
        )}
      </VideoContainer>
    </Container>
  );
}

export default CustomerVideo;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  height: ${(props) => props.containerHeight}px;
  width: ${(props) => props.containerWidth}px;
`;

const VideoContainer = styled.div`
  /* display: flex;
  align-items: flex-start;
  justify-content: center; */
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
`;

const StyledVideo = styled.video`
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -1000;
  overflow: hidden;
`;

const Footer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 20%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const EndCallButton = styled(IconButton)`
  &&& {
    color: white;
    background-color: #ff3232;
    opacity: 0.8;
  }
`;

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
`;
