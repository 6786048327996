import axios from "axios";

axios.defaults.withCredentials = false;

export async function getAllUsers() {
  const response = await axios.get("/api/users/all");
  return response;
}

export async function getDetectionsForImage(image) {
  var formData = new FormData();
  formData.append("image", image)
  const url = '/api/v1/COCO/detect'
  const response = await axios.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }, responseType: 'blob'
  })
  return response
}

export async function getMyUserInfo() {
  const response = await axios.get("/api/users/my-user");
  return response;
}

export async function getMyUserPermission() {
  const response = await axios.get("/api/users/my-user-permission");
  return response;
}

export async function getUserInfo(id) {
  const response = await axios.get(`/api/users/get-user/${id}`);
  return response;
}

export async function createUser(data) {
  try {
    const response = await axios.post("/api/users/create", data);
    return response;
  } catch (err) {
    return err.response.data.message;
  }
}

export async function updateMyUserInfo(firstName, lastName) {
  try {
    const response = await axios.post("/api/users/update-my-user", {
      firstName,
      lastName,
    });
    return response;
  } catch (err) {
    return err.response.data.message;
  }
}

export async function updateUserInfo(data) {
  try {
    const response = await axios.post("/api/users/update", data);
    return response;
  } catch (err) {
    return err.response.data.message;
  }
}

export async function changeUserPassword(data) {
  try {
    const response = await axios.post("/api/users/update-my-password", data);
    return response;
  } catch (err) {
    return err.response.data.message;
  }
}

export async function deleteUser(data) {
  try {
    const response = await axios.post("/api/users/delete", data);
    return response;
  } catch (err) {
    return err.response.data.message;
  }
}
