import React, { useEffect, useState, useContext } from "react";
import { getMyTermsImage, getMyTermsUrl } from "../services/companyService";
import { store } from "../context/store";

import styled from "styled-components";

function AcceptTerms({ onClick, companyID }) {
  const { state, dispatch } = useContext(store);
  const [termsImage, setTermsImage] = useState("");
  const [termsUrl, setTermsUrl] = useState("");

  useEffect(async () => {
    if (companyID !== null) {
      const imageResponse = await getMyTermsImage(companyID);
      if (imageResponse && imageResponse.status === 200) {
        setTermsImage(imageResponse.data);
      }

      const urlResponse = await getMyTermsUrl(companyID);
      if (urlResponse && urlResponse.status === 200) {
        setTermsUrl(urlResponse.data);
      }
    }
  }, companyID);

  return (
    <Container>
      <Logo alt="Default not found" width={"100%"} src={`${process.env.PUBLIC_URL}/${termsImage}`} />
      <p>
        An extra pair of eyes, helps solve everything faster! <br /> <br /> By accepting video call, you agree to our{" "}
        <span><a href={termsUrl} target="blank">terms and conditions</a>.</span>{" "}
      </p>
      <StyledButton onClick={onClick}>Accept</StyledButton>
    </Container>
  );
}

export default AcceptTerms;

const Container = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  /* margin: 0 auto;
  padding: 100px 0; */

  & > p {
    text-align: center;
    width: 200px;
    margin-top: 10px;
    margin-bottom: 35px;
    font-family: "montserrat";
    font-size: 16px;
    font-weight: 300;
  }

  & > p span {
    color: #00a2c5;
    cursor: pointer;
  }
`;

const Logo = styled.img`
  object-fit: contain; // keep aspect ratio
  height: 60px;
  margin: 20px 0;
`;

const StyledButton = styled.button`
  width: 200px;
  height: 50px;
  font-size: large;
  color: #fff;
  background-color: #00a2c5;
  border-radius: 5px;
  border: 0;
  cursor: pointer;
`;
