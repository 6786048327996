import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import auth from "../services/authService";
import { store } from "../context/store";

const ProtectedRoute = ({ path, component: Component, render, ...rest }) => {
  const { state, dispatch } = useContext(store);

  return (
    <Route
      path={path}
      {...rest}
      render={(props) => {
        if (!state.authenticated) {
          return (
            // redirect to page on which is clicked after login
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location },
              }}
            />
          );
        } else {
          return Component ? <Component {...props} /> : render(props);
        }
      }}
    />
  );
};

export default ProtectedRoute;
