import io from "socket.io-client";

let socket;
if (process.env.NODE_ENV === "development") {
  socket = io("http://localhost:8080");
} else {
  socket = io();
}

export { socket };
