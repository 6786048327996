import React, { useEffect, useState, useContext } from "react";
import MuiDataGrid from "./common/datagrid/datagrid";
import Button from '@material-ui/core/Button';
import { store } from "../context/store";
import styled from "styled-components";
import { getCompanyReport, getAllCompanyReport, getAgentReportForCompany } from "../services/reportService";
import { getMyCompanyInfo } from "../services/companyService";
import ReactDatePicker from 'react-datepicker';
import { registerLocale, setDefaultLocale } from  "react-datepicker";
// import nl from 'date-fns/locale/nl';
import { nl } from 'date-fns/esm/locale';
import "react-datepicker/dist/react-datepicker.css";


registerLocale('nl', nl);


function StatisticsTable() {
  const today = new Date();
  const { state, dispatch } = useContext(store);
  const [companyReport, setCompanyReport] = useState([]);
  const [agentReport, setAgentReport] = useState([]);
  const [startDate, setStartDate] = useState(new Date(today.getFullYear(), today.getMonth(), 1));
  const [endDate, setEndDate] = useState(new Date(today.getFullYear(), today.getMonth() + 1, 0));
  const [reloadData, setReloadData] = useState(true);

  const companyColumns = [
    {field: 'name', headerName: 'Name', minWidth: 300, headerClassName: 'mui-grid-column-header'},
    {field: 'maxNumberOfSubscriptions', headerName: 'Max. Subscriptions', minWidth: 150, headerClassName: 'mui-grid-column-header'},
    {field: 'userCount', headerName: '# Users', minWidth: 150, headerClassName: 'mui-grid-column-header'},
    {field: 'smsCount', headerName: '# SMS', minWidth: 150, headerClassName: 'mui-grid-column-header'},
    {field: 'chatCount', headerName: '# Links', minWidth: 150, headerClassName: 'mui-grid-column-header'},
    {field: 'totalCount', headerName: '# Total', minWidth: 150, headerClassName: 'mui-grid-column-header'},
  ];

  const agentColumns = [
    {field: 'lastName', headerName: 'Last Name', minWidth: 200, headerClassName: 'mui-grid-column-header'},
    {field: 'firstName', headerName: 'First Name', minWidth: 200, headerClassName: 'mui-grid-column-header'},
    {field: 'smsCount', headerName: '# SMS', minWidth: 200, headerClassName: 'mui-grid-column-header'},
    {field: 'chatCount', headerName: '# Links', minWidth: 200, headerClassName: 'mui-grid-column-header'},
    {field: 'totalCount', headerName: '# Total', minWidth: 200, headerClassName: 'mui-grid-column-header'},
  ];

  const getReportData = async () => {
    if (state.user.isAdmin) {
      const allCompanyReport = await getAllCompanyReport(startDate, endDate);
      if (allCompanyReport && allCompanyReport.status === 200) {
        setCompanyReport(allCompanyReport.data)
      }
    } else {
      const company = await getMyCompanyInfo();
      // const companyReport = await getCompanyReport(company.data.id, startDate, endDate);
      const agentReport = await getAgentReportForCompany(company.data.id, startDate, endDate);
      // if (companyReport && companyReport.status === 200) {
      //   setCompanyReport(companyReport.data)
      // }
      if (agentReport && agentReport.status === 200) {
        setAgentReport(agentReport.data)
      }
    }
  };

  const setReportData = async () => {
    await getReportData();
  }

   useEffect(async () => {
    if(reloadData){
      await setReportData();
      setReloadData(false);
    }
  }, [reloadData]);

  return (
    <div className="data-grid-company-statistics">
      <div className="data-grid-header">
        <div />
        <div>
          <ReactDatePicker 
              locale="nl" 
              selected={startDate} 
              onChange={(date) => setStartDate(date)} 
              dateFormat="dd-MM-yyyy"
          />
        </div>
        
        <div>
          <ReactDatePicker 
              locale="nl" 
              selected={endDate} 
              onChange={(date) => setEndDate(date)} 
              dateFormat="dd-MM-yyyy" 
          />
        </div>
        <div>
        <Button onClick={() => {setReloadData(true)}} variant="contained" className="btn-primary" disableElevation>Filter</Button>
        </div>
      </div>
      <div>
      <Container>
        {state.user.isAdmin &&
          <StyledMuiDataGrid 
            rows= {companyReport} 
            columns= {companyColumns} 
            loading= {!companyReport.length}
          />
        } 
        {state.user.isCompanyAdmin &&
          <StyledMuiDataGrid 
            rows= {agentReport} 
            columns= {agentColumns} 
            loading= {!agentReport.length}
          />
        }
      </Container>
      </div>
    </div>
  )
}

export default StatisticsTable


const Container = styled.div`
  margin-top: 20px;
  display: block;

  & .MuiPaper-root {
    box-shadow: none !important;
  }

  & .MuiDataGrid-root {
    border: none;
  }

  & .MuiDataGrid-columnSeparator {
    display: none !important;
  }

  & .MuiDataGrid-cell {
    font-family: "Comfortaa" !important;
    font-size: 13px !important;
    font-weight: 300 !important;
  }

  & .MuiIconButton-root {
    :hover {
      background-color: transparent !important;
    }
  }

  & .MuiIcon-root {
    color: lightgray;
    font-size: 20px;
  }
`;

const StyledMuiDataGrid = styled(MuiDataGrid)``;
