import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import axios from "axios";

import { store } from "../context/store";
import SnackbarCustomized from "./common/Snackbar";
import { deviceSize } from "./common/Breakpoints";
import SettingsCompanySelect from "./SettingsCompanySelect";
import {
  createUser,
  getUserInfo,
  updateUserInfo,
} from "../services/userService";
import { getAllCompanies } from "../services/companyService";

import CheckIcon from "@material-ui/icons/Check";

function SettingsFormAdminUser({ id, onCloseUserForm }) {
  const { state, dispatch } = useContext(store);
  const defaultCompanyId = state.user.isAdmin ? "" : state.user.companyId;
  const [userId, setUserId] = useState("");
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyId, setCompanyId] = useState(defaultCompanyId);
  const [isCompanyAdmin, setIsCompanyAdmin] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [listOfCompanies, setListOfCompanies] = useState(null);
  const [error, setError] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("#caf0f8");
  const [snackbarBgColor, setSnackbarBgColor] = useState("");

  
  useEffect(async () => {
    if (id && id !== null) {
      const response = await getUserInfo(id);
      if (response && response.status === 200) {
        setUserId(id);
        setFirstName(response.data.firstName);
        setLastName(response.data.lastName);
        setCompanyId(response.data.company.id);
        setIsCompanyAdmin(response.data.isCompanyAdmin);
        setIsAdmin(response.data.isAdmin);
        setIsActive(response.data.activeSubscription);
        setIsLoading(false);
      }
    }
  }, [id]);

  useEffect(async () => {
    const response = await getAllCompanies();
    if (response && response.status === 200 && response.data !== null) {
      setListOfCompanies(response.data);
    }
  }, []);

  const onChangeSelected = (id) => {
    setCompanyId(id);
  };

  const closeSnackbar = () => {
    setOpenSnackbar(false);
  };
  
  const handleAdminToggle = (adminToggle) => {
    setIsAdmin(adminToggle)
    if (adminToggle) {
      setIsCompanyAdmin(false)
    }
  };

  const handleCompanyAdminToggle = (adminToggle) => {
    setIsCompanyAdmin(adminToggle)
    if (adminToggle) {
      setIsAdmin(false)
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (userId) {
      const data = {
        userId,
        firstName,
        lastName,
        companyId,
        isCompanyAdmin,
        isAdmin,
        activeSubscription: isActive,
      };
      const response = await updateUserInfo(data);
      if (response && response.status === 200) {
        onCloseUserForm();
      } else {
        setOpenSnackbar(true);
        setSnackbarMessage(response);
        setSnackbarBgColor("#ff8484");
      }
    } else {
      const data = {
        email,
        firstName,
        lastName,
        companyId,
        isCompanyAdmin,
        isAdmin,
        activeSubscription: isActive,
      };
      const response = await createUser(data);
      if (response && response.status === 200) {
        onCloseUserForm();
      } else {
        setOpenSnackbar(true);
        setSnackbarMessage(response);
        setSnackbarBgColor("#ff8484");
      }
    }
  };

  if (id && isLoading) {
    return <Container />;
  }

  return (
    <Container>
      <SnackbarCustomized
        onOpen={openSnackbar}
        onCloseSnackbarParent={closeSnackbar}
        message={snackbarMessage}
        backgroundcolor={snackbarBgColor}
        textcolor={"#495057"}
      />
      <StyledForm
        onSubmit={(e) => {
          handleSubmit(e);
        }}
      >
        {!id && (
          <div>
            <StyledTextLabel htmlFor="email">Email:</StyledTextLabel>
            <br />
            <StyledTextInput
              id="email"
              name="email"
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <br />
          </div>
        )}
        <StyledTextLabel htmlFor="firstName">First name:</StyledTextLabel>
        <br />
        <StyledTextInput
          id="firstName"
          name="firstName"
          type="text"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
        <br />
        <StyledTextLabel htmlFor="lastName">Last name:</StyledTextLabel>
        <br />
        <StyledTextInput
          id="lastName"
          name="lastName"
          type="text"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
        <br />
        {state.user.isAdmin && listOfCompanies && (
          <SelectInputContainer>
            <p>Company:</p>
            <SettingsCompanySelect
              selectLabel={"Company"}
              data={listOfCompanies}
              initValue={companyId}
              onChangeSelected={onChangeSelected}
            />
          </SelectInputContainer>
        )}
        <CheckBoxInputContainer>
          <p>Active:</p>
          <RoundedToggleButton
            backgroundColor={isActive ? "#04a1c4" : "#fff"}
            onClick={() => setIsActive(!isActive)}
          >
            {isActive && <CheckIcon />}
          </RoundedToggleButton>
        </CheckBoxInputContainer>
        {state.user.isAdmin && (
          <CheckBoxInputContainer>
            <p>Pegamento:</p>
            <RoundedToggleButton
              backgroundColor={isAdmin ? "#04a1c4" : "#fff"}
              onClick={() => handleAdminToggle(!isAdmin)}
            >
              {isAdmin && <CheckIcon />}
            </RoundedToggleButton>
          </CheckBoxInputContainer>
        )}
        <CheckBoxInputContainer>
          <p>Admin:</p>
          <RoundedToggleButton
            backgroundColor={isCompanyAdmin ? "#04a1c4" : "#fff"}
            onClick={() => handleCompanyAdminToggle(!isCompanyAdmin)}
          >
            {isCompanyAdmin && <CheckIcon />}
          </RoundedToggleButton>
        </CheckBoxInputContainer>
        <br />
        {error && <ErrorSpan>{error}</ErrorSpan>}
        <br />
        {id ? (
          <StyledSubmitContainer>
            <StyledSubmit type="submit" value="Update" />
          </StyledSubmitContainer>
        ) : (
          <StyledSubmitContainer>
            <StyledSubmit type="submit" value="Save" />
          </StyledSubmitContainer>
        )}
      </StyledForm>
    </Container>
  );
}

export default SettingsFormAdminUser;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  /* overflow: scroll; */
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 10px;
  background-color: white;
  padding-left: 30px;
  padding-top: 15px;
  padding-bottom: 15px;
  @media (max-width: ${deviceSize.tablet}px) {
    padding-left: 0px;
  }
`;

const StyledForm = styled.form`
  width: 100%;
`;

const StyledTextLabel = styled.label`
  font-family: "Comfortaa";
  font-size: 11px;
  color: #77727a;
  font-weight: 300;
  position: relative;
  top: 9px;
  left: 19px;
  background-color: white;
`;

const StyledTextInput = styled.input`
  width: 50%;
  height: 40px;
  min-width: 300px;
  max-width: 500px;
  font-family: "Comfortaa";
  flex: 1;
  outline: 0;
  border: 1px solid whitesmoke;
  border-radius: 8px;
  background-color: white;
  padding: 0 12px;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0.5px;
  color: #303030;
  @media (max-width: ${deviceSize.tablet}px) {
    min-width: 200px;
  }
  @media (max-width: ${deviceSize.mobile}px) {
    min-width: 150px;
  }
`;

const StyledTextArea = styled.textarea``;

const StyledFieldSet = styled.fieldset``;

const StyledSubmitContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 30px;
  margin-right: 50px;
  margin-bottom: 30px;
`;

const StyledSubmit = styled.input`
  border-radius: 4px;
  text-decoration: none;
  border: none;
  text-align: center;
  font-family: "Comfortaa";
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.5px;
  padding: 13px 13px;
  background-color: #04a1c4;
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;

  :hover {
    opacity: 0.7;
  }
  :active {
    transform: scale(1.05);
    box-shadow: rgba(0, 0, 0, 0.18) 2px 6px 10px;
  }
`;

const CheckBoxInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 40px;
  width: 20%;
  max-width: 200px;
  min-width: 150px;
  margin: 15px 0;

  & > p {
    flex: 1;
    font-family: "Comfortaa";
    font-size: 12px;
    color: gray;
    font-weight: 300;
    margin-right: 25px;
    margin-left: 12px;
  }
`;

const RoundedToggleButton = styled.div`
  display: flex;
  height: 20px;
  width: 20px;
  border-radius: 4px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.backgroundColor || "#04a1c4"};
  border: 1px solid #04a1c4;
  box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  :hover {
    opacity: 0.7;
  }
  & .MuiSvgIcon-root {
    font-size: 16px;
    color: #fff;
  }
`;

const SelectInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 40px;
  width: 305px;
  margin: 15px 0;

  & > p {
    flex: 1;
    font-family: "Comfortaa";
    font-size: 12px;
    color: gray;
    font-weight: 300;
    margin-right: 25px;
    margin-left: 12px;
  }
`;

const ErrorSpan = styled.span`
  font-family: "montserrat";
  font-style: italic;
  font-size: 12px;
  font-weight: 300;
  margin-top: 8px;
  margin-bottom: 8px;
  color: #333;
`;
