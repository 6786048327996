import axios from "axios";
import jwtDecode from "jwt-decode";
import { getJwt, verifyExpiration } from "./authService";

axios.defaults.withCredentials = true;

export async function getAllCompanies() {
  const response = await axios.get("/api/companies/all");
  return response;
}

export async function getMyCompanyInfo() {
  const response = await axios.get("/api/companies/my-company");
  return response;
}

export async function getMyTermsImage(id) {
  const response = await axios.get(`/api/companies/my-termsimage/${id}`);
  return response;
}

export async function getMyTermsUrl(id) {
  const response = await axios.get(`/api/companies/my-termsurl/${id}`);
  return response;
}

export async function getCompanyInfo(id) {
  const response = await axios.get(`/api/companies/get-company/${id}`);
  return response;
}

export async function createCompany(data) {
  try {
    const formdata = new FormData();
    for (var key in data) {
      formdata.append(key, data[key]);
    }

    const response = await axios.post("/api/companies/create", formdata, { headers: { "Content-Type": "multipart/form-data" } });
    return response;
  } catch (err) {
    return err.response.data.message;
  }
}

export async function updateCompany(data) {
  try {
    const formdata = new FormData();
    for (var key in data) {
      formdata.append(key, data[key]);
    }

    const response = await axios.post("/api/companies/update", formdata, { headers: { "Content-Type": "multipart/form-data" } });

    return response;
  } catch (err) {
    return err.response.data.message;
  }
}

export async function deleteCompany(data) {
  try {
    const response = await axios.post("/api/companies/delete", data);
    return response;
  } catch (err) {
    return err.response.data.message;
  }
}
