import React, { createContext, useReducer } from "react";
import Reducer from "./reducer";

let authenticated = localStorage.getItem("authenticated") ? localStorage.getItem("authenticated") : false;

const initialState = {
  authenticated: authenticated,
  user: ""
};

const store = createContext(initialState);

const { Provider } = store;

const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, initialState);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { store, StateProvider };
