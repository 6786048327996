import React from "react";
import styled from "styled-components";

function RedirectAfterCall({ onClick }) {
  return (
    <Container>
      <Logo src={`${process.env.PUBLIC_URL}/assets/images/logo_pegamento_text.jpg`} />
      <p>
        We hope that your problem was solved. Please help us improve by rating this service.
      </p>

    </Container>
  );
}

export default RedirectAfterCall;

const Container = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  /* margin: 0 auto;
  padding: 100px 0; */

  & > p {
    text-align: center;
    width: 300px;
    margin-top: 10px;
    margin-bottom: 35px;
    font-family: "montserrat";
    font-size: 16px;
    font-weight: 300;
  }
`;

const Logo = styled.img`
  object-fit: contain; // keep aspect ratio
  height: 60px;
  margin: 20px 0;
`;

const StyledButton = styled.button`
  width: 200px;
  height: 50px;
  font-size: large;
  color: #fff;
  background-color: #00a2c5;
  border-radius: 5px;
  border: 0;
  cursor: pointer;
`;
