import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import SocketContext from "../context/SocketContext";
import { store } from "../context/store";
import { sendMessage } from "../services/messageService";
import { createRoom } from "../services/roomService";
import { initPeer } from "../services/initPeer";
import SnapEditor from "../components/SnapEditor";
import { deviceSize } from "../components/common/Breakpoints";
import SnackbarCustomized from "../components/common/Snackbar";
import { createChatLog } from "../services/chatService";
import { closeRoom } from "../services/roomService";

import { Tooltip } from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import CallEndIcon from "@material-ui/icons/CallEnd";
import VideocamIcon from "@material-ui/icons/Videocam";
import Layout from "../components/layout/Layout";

function DashboardVideo() {
  const socket = useContext(SocketContext);
  const { state, dispatch } = useContext(store);
  const [newPeerConnection, setNewPeerConnection] = useState(true);
  const [myPeer, setMyPeer] = useState(() => initPeer());
  const initRoomId = Math.random().toString(36).substr(2, 5);
  const [roomId, setRoomId] = useState(initRoomId);
  const [myId, setMyId] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [videoHeight, setVideoHeight] = useState(null);
  const [videoWidth, setVideoWidth] = useState(null);
  const [aspectRatio, setAspectRatio] = useState(1.33);
  const [snapAspectRatio, setSnapAspectRatio] = useState(1.33);
  const [resizedWidthPercentage, setResizedWidthPercentage] = useState(null);
  const [resizedWidthPixels, setResizedWidthPixels] = useState(null);
  const [snapshot, setSnapshot] = useState(null);
  const [countryCode, setCountryCode] = useState("+31");
  const [acceptTermsBool, setAcceptTermsBool] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("Password updated");
  const [snackbarBgColor, setSnackbarBgColor] = useState("#caf0f8");
  const [refreshEditor, setRefreshEditor] = useState(false);

  const partnerVideo = useRef();
  const photoRef = useRef(null); // Generate a photo snaphshot

  let baseUrl;
  if (process.env.NODE_ENV === "development") {
    baseUrl = "http://localhost:3000";
  } else {
    baseUrl = process.env.REACT_APP_BASE_URL;
    if (state.user && state.user !== "") {
      if (state.user.company.domainActive) {
        baseUrl = state.user.company.baseUrl
          ? state.user.company.baseUrl
          : process.env.REACT_APP_BASE_URL;
      }
    } else {
      baseUrl = process.env.REACT_APP_BASE_URL;
    }
  }

  useEffect(() => {
    if (newPeerConnection) {
      myPeer.on("open", function (id) {
        setMyId(id);
        socket.emit("join-room", roomId, id);
      });

      myPeer.on("call", (call) => {
        // Answer the call
        call.answer();
        call.on("stream", (remoteMediaStream) => {
          partnerVideo.current.srcObject = remoteMediaStream;
          partnerVideo.current.addEventListener("resize", handleResize);
        });
      });

      myPeer.on("error", function (err) {
        console.log(err);
      });

      setRefreshEditor(false);
      setNewPeerConnection(false);
    }
  }, [newPeerConnection]);

  socket.on("accept-terms", (acceptTermsBool) => {
    setAcceptTermsBool(acceptTermsBool);
  });

  socket.on("join-room", (id) => {
    if (state.user.company != null) {
      socket.emit("company-id", {
        roomId: roomId,
        id: state.user.company.id
      });
    }
  });

  const logChat = (myId, roomId) => {
    const data = { room: roomId, agent: myId };
    const response = createChatLog(data);
  }

  const sendNewMessage = async () => {
    try {
      if (!state.user.activeSubscription || !state.user.company.subscription) {
        setOpenSnackbar(true);
        setSnackbarMessage("Subscription not active");
        setSnackbarBgColor("#ff8484");
      } else {
        const data = {
          countryCode: countryCode,
          phoneNumber: phoneNumber,
          link: `${baseUrl}/chat/${myId}/${roomId}`,
        };
        const response = await sendMessage(data);

        if (response && response.status == 200) {
          setOpenSnackbar(true);
          setSnackbarMessage("Message sent");
          setSnackbarBgColor("#caf0f8");
          
          const data = {
            room_id: roomId,
            agent_id: myId,
            companyId: state.user.companyId
          }
          createRoom(data)

        } else if (response) {
          setOpenSnackbar(true);
          setSnackbarMessage(response);
          setSnackbarBgColor("#ff8484");
        }
        setCountryCode("+31");
        setPhoneNumber(""); // reset phone number to "" after sending message
      }
    } catch (err) {
      console.log(err);
    }
  };

  const copyLink = () => {
    try {
      if (!state.user.activeSubscription || !state.user.company.subscription) {
        setOpenSnackbar(true);
        setSnackbarMessage("Subscription not active");
        setSnackbarBgColor("#ff8484");
      } else {
        setOpenSnackbar(true);
        setSnackbarMessage("Link copied");
        setSnackbarBgColor("#caf0f8");
        logChat(myId, roomId);
        const link = `${baseUrl}/chat/${myId}/${roomId}`;
        navigator.clipboard.writeText(link);

        const data = {
          room_id: roomId,
          agent_id: myId,
          companyId: state.user.companyId
        }
        createRoom(data)
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleAspectRatio = () => {
    if (videoWidth !== null && videoHeight !== null) {
      setAspectRatio(videoWidth / videoHeight);
    }
  };

  useEffect(() => {
    handleAspectRatio();
  }, [videoWidth, videoHeight]);

  const setWidthByAspectRatio = () => {
    if (aspectRatio >= 1) {
      setResizedWidthPercentage(70); // Percentage of video window
      setResizedWidthPixels(400);
    } else {
      setResizedWidthPercentage(45);
      setResizedWidthPixels(290);
    }
  };

  useEffect(() => {
    setWidthByAspectRatio();
  }, [aspectRatio]);

  const takeSnapshot = () => {
    try {
      if (videoWidth !== null && videoHeight !== null) {
        socket.emit("take-snapshot", {
          roomId: roomId,
          message: "Your snapshot has been taken",
        });
        let video = partnerVideo.current;
        let photo = photoRef.current;
        let ctx = photo.getContext("2d");
        if (aspectRatio !== null && videoWidth !== null) {
          let width = resizedWidthPixels;
          let height = resizedWidthPixels / aspectRatio;
          photo.width = width;
          photo.height = height;
          ctx.drawImage(video, 0, 0, width, height);
        }

        const imageUrl = photo.toDataURL("image/jpeg");
        setSnapAspectRatio(aspectRatio);
        setSnapshot(imageUrl);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleSendSnapshot = (snapshotUrl) => {
    try {
      if (snapshot && snapshot !== null) {
        socket.emit("display-snapshot", {
          roomId: roomId,
          image: snapshotUrl,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const closeSnackbar = () => {
    setOpenSnackbar(false);
  };

  socket.on("call-ended", (callEndedBool) => {
    if (callEndedBool) {
      partnerVideo.current.srcObject = null;
      if (!myPeer.disconnected || !myPeer.destroyed) {
        const data = {
          room_id: roomId
        }
        closeRoom(data)

        setRoomId(Math.random().toString(36).substr(2, 5));
        myPeer.disconnect();
        myPeer.destroy();
        setAspectRatio(1.33);
        setAcceptTermsBool(false);
        setRefreshEditor(true);
        setMyPeer(() => initPeer());
        setNewPeerConnection(true);
      }
    }
  });

  const leaveCall = () => {
    try {
      socket.emit("call-ended", {
        roomId: roomId,
        callEndedBool: true,
      });
      partnerVideo.current.srcObject = null;
      setAspectRatio(1.33);
      setAcceptTermsBool(false);
      const data = {
        room_id: roomId,       
      }

      closeRoom(data)
      setRoomId(Math.random().toString(36).substr(2, 5));
      if (!myPeer.disconnected || !myPeer.destroyed) {
        myPeer.disconnect();
        myPeer.destroy();
        setRefreshEditor(true);
        setMyPeer(() => initPeer());
        setNewPeerConnection(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  socket.on("send-error", (error) => {
    console.log(error);
  });

  const handleResize = (event) => {
    setVideoHeight(event.target.videoHeight);
    setVideoWidth(event.target.videoWidth);
  }

  return (
    <Layout headerTitle="Dashboard">
      <SnackbarCustomized
        onOpen={openSnackbar}
        onCloseSnackbarParent={closeSnackbar}
        message={snackbarMessage}
        backgroundcolor={snackbarBgColor}
        textcolor={"#495057"}
      />
      <ContentContainer>
        <ContentLeft>
          <VideoContainer widthProp={resizedWidthPercentage}>
            <h1>Video</h1>
            <VideoWrapper aspectRatio={aspectRatio}>
              <StyledTooltip title={"Take snapshot"} placement="right">
                <StyledRoundedInVideoButton
                  btnMargin={"15px 15px 0 0"}
                  onClick={takeSnapshot}
                >
                  <CameraAltIcon />
                </StyledRoundedInVideoButton>
              </StyledTooltip>
              <StyledTooltip title={"End call"} placement="right">
                <StyledRoundedInVideoButton
                  btnMargin={"75px 15px 0 0"}
                  bgColor={"#ff3232"}
                  onClick={leaveCall}
                >
                  <CallEndIcon />
                </StyledRoundedInVideoButton>
              </StyledTooltip>
              <StyledInVideoOverlay>
                <StyledInVideoBgPicture>
                  <VideocamIcon />
                </StyledInVideoBgPicture>
              </StyledInVideoOverlay>
              <StyledVideo
                ref={partnerVideo}
                playsInline
                autoPlay
                muted
                light
                hidden={!acceptTermsBool} // hide video until terms accepted
                onLoadedMetadata={(e) => {
                  setVideoHeight(e.target.videoHeight);
                  setVideoWidth(e.target.videoWidth);
                }}
              />
            </VideoWrapper>
            <PhoneInputContainer>
              <CountryCodeInput
                value={countryCode}
                onChange={(e) => setCountryCode(e.target.value)}
                placeholder="+31"
              />
              <Input
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                placeholder="Mobile phone"
              />
              <StyledTooltip title={"Send message"} placement="bottom">
                <SendButton onClick={sendNewMessage}>
                  <SendIcon />
                </SendButton>
              </StyledTooltip>
              <StyledTooltip title={"Copy link"} placement="bottom">
                <StyledRoundedButton onClick={copyLink}>
                  <FileCopyOutlinedIcon />
                </StyledRoundedButton>
              </StyledTooltip>
            </PhoneInputContainer>
          </VideoContainer>
        </ContentLeft>
        <ContentRight>
          <ToolsContainer>
            <h1>Snap</h1>

            <SnapEditor
              onSendSnapshot={handleSendSnapshot}
              aspectRatio={snapAspectRatio}
              resizedWidth={resizedWidthPixels}
              snapshot={snapshot}
              refreshEditor={refreshEditor}
            />
            <CanvasContainer>
              <Canvas ref={photoRef} hidden />
            </CanvasContainer>
          </ToolsContainer>
        </ContentRight>
      </ContentContainer>
    </Layout>
  );
}

export default DashboardVideo;

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;

  @media (max-width: ${deviceSize.tablet - 1}px) {
    flex-direction: column;
    align-items: center;
  }
`;

const ContentLeft = styled.div`
  flex: 0.5;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 50px;
  /* background-color: red; */

  @media (max-width: ${deviceSize.tablet - 1}px) {
    flex: 1;
    width: 100%;
    align-items: flex-start;
    padding-left: 100px;
  }
  @media (max-width: ${deviceSize.mobile - 1}px) {
    padding-left: 0;
    align-items: center;
  }
`;

const ContentRight = styled.div`
  flex: 0.5;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 50px;
  /* background-color: lightgray; */
  @media (max-width: ${deviceSize.tablet - 1}px) {
    flex: 1;
    width: 100%;
    align-items: flex-start;
    padding-left: 100px;
  }
  @media (max-width: ${deviceSize.mobile + 1}px) {
    padding-left: 0;
    padding-bottom: 50px;
    align-items: center;
  }
`;

const VideoContainer = styled.div`
  width: ${(props) => props.widthProp}%;
  min-width: 300px;
  max-width: 550px;
  /* background-color: gray; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: ${deviceSize.tablet}px) {
    width: 65%;
  }
  /* @media (max-width: ${deviceSize.mobile - 1}px) {
    width: 70%;
  } */

  & > h1 {
    font-family: "Comfortaa";
    font-size: 26px;
    color: gray;
    font-weight: 300;
    align-self: flex-start;
    margin-bottom: 20px;
  }
`;

const VideoWrapper = styled.div`
  width: 100%;
  aspect-ratio: ${(props) =>
    props.aspectRatio}; // aspect ratio = width / height
  background-color: #fff;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
`;

const StyledVideo = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;

const PhoneInputContainer = styled.div`
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
`;

const CountryCodeInput = styled.input`
  height: 45px;
  font-family: montserrat;
  width: 20px;
  outline: 0;
  border: none;
  border-radius: 8px;
  background-color: white;
  padding: 0 12px;
  margin-right: 6px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: #303030;
  text-transform: uppercase;
  box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
`;

const Input = styled.input`
  height: 45px;
  font-family: montserrat;
  flex: 1;
  outline: 0;
  border: none;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  background-color: white;
  padding: 0 12px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: #303030;
  text-transform: uppercase;
  box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
`;

const SendButton = styled.div`
  display: flex;
  height: 45px;
  width: 47px;
  background-color: #04a1c4;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 16px;
  box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
  :hover {
    /* background-color: #fff; */
    opacity: 0.8;
  }
  & .MuiSvgIcon-root {
    font-size: 26px;
    color: white;
  }
`;

const StyledRoundedButton = styled.div`
  display: flex;
  height: 45px;
  width: 45px;
  border-radius: 8px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  :hover {
    opacity: 0.7;
  }
  :active {
    transform: scale(1.05);
    box-shadow: rgba(0, 0, 0, 0.18) 2px 6px 10px;
  }
  & .MuiSvgIcon-root {
    font-size: 24px;
    color: #a6acb4;
  }
`;

const StyledRoundedInVideoButton = styled(StyledRoundedButton)`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 100;
  margin: ${(props) => props.btnMargin || "0px"};
  background-color: ${(props) => props.bgColor || "#04a1c4"};
  opacity: 0.8;
  :hover {
    opacity: 1;
  }
  & .MuiSvgIcon-root {
    font-size: 24px;
    color: ${(props) => props.iconColor || "#fff"};
  }
`;

const StyledInVideoOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledInVideoBgPicture = styled.div`
  & .MuiSvgIcon-root {
    font-size: 120px;
    color: lightgray;

    @media (max-width: ${deviceSize.tablet - 1}px) {
      font-size: 80px;
    }
  }
`;

const ToolsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & > h1 {
    font-family: "Comfortaa";
    font-size: 26px;
    color: gray;
    font-weight: 300;
    align-self: flex-start;
    margin-bottom: 20px;
  }
`;

const CanvasContainer = styled.div``;

const Canvas = styled.canvas`
  width: 100%;
  border-radius: 20px;
`;

const StyledTooltip = styled((props) => (
  <Tooltip classes={{ popper: props.className }} {...props} />
))`
  & .MuiTooltip-tooltip {
    background-color: #222433;
    color: #fff;
    font-size: 13px;
    font-family: "montserrat";
    padding: 5px 15px;
    font-weight: 300;
    border-radius: 6px;
  }
`;

// const StyledVideo = styled.video`
//   height: 100%;
//   width: 100%;
//   object-fit: cover;
//   object-position: center;
//   border-radius: 20px;
//   z-index: -1000;
//   max-width: 600px;
//   /* background:transparent url('https://s3.amazonaws.com/msc-media-linux-production/5e0ea029945d6.gif') no-repeat 0 0;
//   background-size:cover; */
//   @media (max-width: 920px) {
//     height: 370px;
//     width: 300px;
//   }
/* min-width: 300px;

  width: 100%;
  height: auto;
  max-height: 500px;
  overflow: hidden;
  border: 4px solid whitesmoke; */
// `;
