import React, { useState } from 'react'
import styled from "styled-components";

import SettingsTableCompanies from "./SettingsTableCompanies";
import SettingsFormAdminCompany from "./SettingsFormAdminCompany";
import CloseIcon from "@material-ui/icons/Close";
import BusinessIcon from "@material-ui/icons/Business";

function SettingsAllCompanies() {

    const [companyForm, setCompanyForm] = useState(false);
    const [companyFormId, setCompanyFormId] = useState(null);
    
    const handleCompanyCreateUpdateForm = (id) => {
      setCompanyFormId(id);
      setCompanyForm(true);
    };
    
    const handleCloseCompanyForm = () => {
      setCompanyFormId(null);
      setCompanyForm(false);
    };

    return (
        !companyForm ? (
            <div title="companyForm">
            <ButtonContainer>
                <RoundedButton title="Create company" onClick={() => handleCompanyCreateUpdateForm()}>
                <BusinessIcon />
                </RoundedButton>
            </ButtonContainer>
            <SettingsTableCompanies
                onCompanyCreateUpdateForm={handleCompanyCreateUpdateForm}
            />
            </div>
        ) : (
            <div>
            <ButtonContainer>
                <RoundedButton onClick={handleCloseCompanyForm}>
                <CloseIcon />
                </RoundedButton>
            </ButtonContainer>
            <SettingsFormAdminCompany
                id={companyFormId}
                onCloseCompanyForm={handleCloseCompanyForm}
            />
            </div>
        )
    )
}

export default SettingsAllCompanies

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const RoundedButton = styled.div`
  display: flex;
  height: 45px;
  width: 45px;
  border-radius: 8px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  background-color: #04a1c4;
  box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  :hover {
    opacity: 0.7;
  }
  :active {
    transform: scale(1.05);
    box-shadow: rgba(0, 0, 0, 0.18) 2px 6px 10px;
  }
  & .MuiSvgIcon-root {
    font-size: 24px;
    color: #fff;
  }
`;