import React, { useEffect, useState } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import Fade from "@material-ui/core/Fade";
import styled from "styled-components";

export default function SnackbarCustomized({
  onOpen,
  onCloseSnackbarParent,
  message,
  backgroundcolor,
  textcolor,
  autoHideDuration=2000
}) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (onOpen === true) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [onOpen]);

  const handleClose = () => {
    setOpen(false);
    onCloseSnackbarParent();
  };

  return (
    <div>
      <StyledSnackbar
        open={open}
        onClose={handleClose}
        autoHideDuration={autoHideDuration}
        TransitionComponent={Fade}
        message={message}
        backgroundcolor={backgroundcolor}
        textcolor={textcolor}
        key={Fade}
      />
    </div>
  );
}

const StyledSnackbar = styled(Snackbar)`
  & .MuiSnackbarContent-root {
    background-color: ${(props) => props.backgroundcolor || "#fff"};
    color: ${(props) => props.textcolor || "#000"};
    font-family: "Comfortaa";
    font-size: 15px;
    font-weight: 300;
    margin-bottom:40px;
  }
`;
