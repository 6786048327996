import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import styled from "styled-components";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function SettingsCompanySelect({
  selectLabel,
  data,
  initValue,
  onChangeSelected,
}) {
  const classes = useStyles();
  const [selectedValue, setSelectedValue] = useState(initValue);

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    onChangeSelected(event.target.value);
  };

  return (
    <div>
      <StyledFormControl className={classes.formControl}>
        <StyledSelect
          labelId="styled-select-label"
          id="styled-select"
          value={selectedValue}
          onChange={handleChange}
        >
          {data.map((item) => (
            <StyledMenuItem key={item.id} value={item.id}>
              {item.name}
            </StyledMenuItem>
          ))}
        </StyledSelect>
      </StyledFormControl>
    </div>
  );
}

const StyledFormControl = styled(FormControl)``;

const StyledSelect = styled(Select)``;

const StyledMenuItem = styled(MenuItem)``;
