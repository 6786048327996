import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { dividerClasses } from '@mui/material';

const MuiDataGrid = ({ rows, columns, loading }) => {
    
    return (
        <div style={{ height: 500, width: '100%' }}>
            <DataGrid 
                rows= {rows}
                columns= {columns}
                loading= {loading}
            />
        </div>
    )
}

export default MuiDataGrid