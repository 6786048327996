import axios from "axios";
import jwtDecode from "jwt-decode";

const tokenKey = "token";

axios.defaults.withCredentials = true; // Use with express-session cookies

export async function login(email, password) {
  const response = await axios.post("/api/auth/login", { email, password });
  if (response && response.status === 200) {
    localStorage.setItem("authenticated", true);
  }
  return response;
}

export async function logout() {
  const response = await axios.get("/api/auth/logout");
  localStorage.removeItem("authenticated");
  return response;
}

export function verifyExpiration(jwt) {
  let isExpired = false;
  let decodedJwt = jwtDecode(jwt);
  const dateNow = new Date();
  if (decodedJwt.exp * 1000 < dateNow.getTime()) {
    isExpired = true;
  }
  return isExpired;
}

export function getJwt() {
  return localStorage.getItem(tokenKey);
}

export async function isAuthenticated() {
  try {
    const response = await axios.get("/api/auth/authenticated");
    return response;
  } catch (err) {
    console.log(err);
  }
}

export async function resetPassword(email) {
  try {
    const response = await axios.post("/api/auth/reset-password", { email });
    return response;
  } catch (err) {
    console.log(err);
  }
}

export default {
  login,
  logout,
  isAuthenticated,
  getJwt,
  verifyExpiration,
  resetPassword,
};
