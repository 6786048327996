import React, {useContext} from "react";
import styled from "styled-components";
import { store } from "../context/store";
import { deviceSize } from "../components/common/Breakpoints";
import Layout from "../components/layout/Layout";
import StatisticsForMyCompany from '../components/StatisticsForMyCompany';


function DashboardPerformance() {
  const { state, dispatch } = useContext(store);
    return (
        <Layout headerTitle="Performance">
            <ContentContainer>
                <ContentPerformanceContainer>
                    <StyledHeader>
                        Performance
                    </StyledHeader>
                    {state.user.isCompanyAdmin && 
                      <StatisticsForMyCompany />
                    }
                </ContentPerformanceContainer>
            </ContentContainer>
        </Layout>
    );
}

export default DashboardPerformance;


const ContentContainer = styled.div`
  margin-top: 55px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  @media (max-width: ${deviceSize.tablet}px) {
    margin-left: 0px;
  }
`;


const ContentPerformanceContainer = styled.div`
  width: 55%;
  padding: 10rem;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 10px;
  background-color: white;
  padding-top: 5rem;
  padding-bottom: 5rem;
  margin-bottom: 50px;
  @media (max-width:900px) {
    width:600px;
    margin-left: 0px;
  }
  @media (max-width: 768px) {
    width:500px;
    margin-left: 0px;
  }
  @media (max-width: 600px) {
    width:300px;
    margin-left: 0px;
  }
  @media (max-width: 426px) {
    width:300px;
    margin-left: 0px;
  }
`;

const StyledHeader = styled.h1`
  font-size: 20px;
  text-align: left;
  color: #000;
  font-family: "Comfortaa";
  color: #77727a;
  font-weight: 300;
`;
