const Reducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        authenticated: action.payload.authenticated,
        user: action.payload.user
      };
    case "LOGOUT":
      return {
        ...state,
        authenticated: false,
        user: ""
      };
    case "UPDATE_USER":
      return {
        ...state,
        user: {
          ...state.user,
          firstName: action.payload.firstName,
          lastName: action.payload.lastName
        }
      };
    case "UPDATE_COMPANY":
      return {
        ...state,
        user : {
          ...state.user,
          company: {
            ...state.user.company,
            baseUrl: action.payload.baseUrl,
            name: action.payload.name,
            subscription: action.payload.subscription,
            textMessage: action.payload.textMessage,
          }
        }
      };
    default:
      return state;
  }
};

export default Reducer;
