const deviceSize = {
  mobile: "426",
  tablet: "768",
  laptop: "1024",
};

const device = {
  mobile: `(min-width: ${deviceSize.mobile}px)`,
  tablet: `(min-width: ${deviceSize.tablet}px)`,
  laptop: `(min-width: ${deviceSize.laptop}px)`,
};

export { deviceSize, device };
