import Peer from "peerjs";

export function initPeer() {
  const id = Math.random().toString(36).substr(2, 5);
  let peer;
  if (process.env.NODE_ENV === "development") {
    peer = new Peer(id, {
      host: "localhost",
      port: 9000,
      path: "/peerserver",
    });
  } else {
    peer = new Peer(id, {
      host: process.env.REACT_APP_BASE_URL,
      port: 443,
      secure: true,
      path: "/peerserver",
      debug: 0,
      config: {
        iceServers: [
          { urls: "stun:stun.l.google.com:19302" },
          {
            url: "turn:numb.viagenie.ca:3478",
            credential: "muazkh",
            username: "webrtc@live.com",
          },
          {
            url: "turn:numb.viagenie.ca",
            credential: "muazkh",
            username: "webrtc@live.com",
          },
          {
            urls: "turn:0.peerjs.com:3478",
            username: "peerjs",
            credential: "peerjsp",
          },
        ],
        sdpSemantics: "unified-plan",
      },
    });
  }
  return peer;
}

export default {
  initPeer,
};
