import axios from "axios";

axios.defaults.withCredentials = true;

export async function createRoom(data) {
  try {
    const response = await axios.post("/api/room/create", data);
    return response;
  } catch (err) {
    return err.response.data.message;
  }
}

export async function getRoomValidity(roomId, agentId) {
  const response = await axios.get("/api/room/valid", { params: { roomId: roomId, agentId: agentId } });
  return response;
}

export async function closeRoom(data) {
  const response = await axios.post("/api/room/close", data);
  return response;
}