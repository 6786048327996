import React, { useEffect, useState, useContext } from "react";
import { withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import styled from "styled-components";

import { store } from "../context/store";
import SettingsFormMyUser from "./SettingsFormMyUser";
import SettingsFormChangePassword from "./SettingsFormChangePassword";
import SettingsTableUsers from "./SettingsTableUsers";
import SettingsTableCompanies from "./SettingsTableCompanies";
import SettingsFormAdminUser from "./SettingsFormAdminUser";
import SettingsFormAdminCompany from "./SettingsFormAdminCompany";
import SettingsFormAdminMyCompany from "./SettingsFormAdminMyCompany";
import SettingsAllCompanies from "./SettingsAllCompanies";
import StatisticsForMyCompany from "./StatisticsForMyCompany";
import { getMyUserPermission } from "../services/userService";
import { useWindowSize } from "../hooks/windowSize";
import { deviceSize } from "./common/Breakpoints";

import PersonAddIcon from "@material-ui/icons/PersonAdd";
import CloseIcon from "@material-ui/icons/Close";
import BusinessIcon from "@material-ui/icons/Business";
import AddIcon from "@material-ui/icons/Add";

const StyledTabsMUI = withStyles({
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > span": {
      maxWidth: 40,
      width: "100%",
      backgroundColor: "#04a1c4",
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTabMUI = withStyles((theme) => ({
  root: {
    textTransform: "none",
    color: "#fff",
    "&:focus": {
      opacity: 1,
    },
  },
}))((props) => <Tab disableRipple {...props} />);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

export default function SettingsTabs() {
  const { state, dispatch } = useContext(store);
  const size = useWindowSize();
  const [value, setValue] = useState(0);
  const [userForm, setUserForm] = useState(false);
  const [userFormId, setUserFormId] = useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleUserCreateUpdateForm = (id) => {
    setUserFormId(id);
    setUserForm(true);
  };

  const handleCloseUserForm = () => {
    setUserFormId(null);
    setUserForm(false);
  };

  return (
    <Container>
      <TabsContainer>
        <StyledTabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          {/* 0 */}
          <StyledTab label="My settings" /> 
          {/* 1 */}
          <StyledTab label="Password" />
          {/* 2 */}
          {(state.user.isAdmin || state.user.isCompanyAdmin) && <StyledTab label="Users" />}
          {/* 3 */}
          {state.user.isAdmin && <StyledTab label="Companies" />}
          {/* 3 */}
          {state.user.isCompanyAdmin && <StyledTab label="Company info" />}
          {/* 4 */}
          {(state.user.isAdmin || state.user.isCompanyAdmin) && <StyledTab label="Statistics" />}
          
          
        </StyledTabs>
      </TabsContainer>
      <StyledTabPanel value={value} index={0}>
        <TabPanelContent>
          <SettingsFormMyUser />
        </TabPanelContent>
      </StyledTabPanel>
      <StyledTabPanel value={value} index={1}>
        <TabPanelContent>
          <SettingsFormChangePassword />
        </TabPanelContent>
      </StyledTabPanel>
      <StyledTabPanel value={value} index={2}>
        <TabPanelContent>
          {!userForm ? (
            <div>
              <ButtonContainer>
                <RoundedButton onClick={() => handleUserCreateUpdateForm()}>
                  <PersonAddIcon />
                </RoundedButton>
              </ButtonContainer>
              <SettingsTableUsers
                onUserCreateUpdateForm={handleUserCreateUpdateForm}
              />
            </div>
          ) : (
            <div>
              <ButtonContainer>
                <RoundedButton onClick={handleCloseUserForm}>
                  <CloseIcon />
                </RoundedButton>
              </ButtonContainer>
              <SettingsFormAdminUser
                id={userFormId}
                onCloseUserForm={handleCloseUserForm}
              />
            </div>
          )}
        </TabPanelContent>
      </StyledTabPanel>
      {state.user.isAdmin && 
        <StyledTabPanel value={value} index={3}>
          <TabPanelContent>
            <SettingsAllCompanies />
          </TabPanelContent>
        </StyledTabPanel>
      }
      {state.user.isCompanyAdmin && 
        <StyledTabPanel value={value} index={3}>
          <TabPanelContent>
            <SettingsFormAdminMyCompany />
          </TabPanelContent>
        </StyledTabPanel>
      }
      <StyledTabPanel value={value} index={4}>
        <TabPanelContent>
          <StatisticsForMyCompany />
        </TabPanelContent>
      </StyledTabPanel>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TabsContainer = styled.div`
  background-color: #fff;
  width: 100%;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;

`;

const StyledTabs = styled(StyledTabsMUI)`
  &&& {
    background-color: white;
  }
`;

const StyledTab = styled(StyledTabMUI)`
  &&& {
    color: gray;
    font-family: "Comfortaa";
    font-size: 14px;
    font-weight: 100;
/* 
    @media (max-width: 1024px) {
      margin-left: -30px;
    }
    @media (max-width: 768px) {
      width: 50px;
    } */
  }
`;

const StyledTabPanel = styled(TabPanel)`
  width: 100%;
`;

const TabPanelContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 0 40px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const RoundedButton = styled.div`
  display: flex;
  height: 45px;
  width: 45px;
  border-radius: 8px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  background-color: #04a1c4;
  box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  :hover {
    opacity: 0.7;
  }
  :active {
    transform: scale(1.05);
    box-shadow: rgba(0, 0, 0, 0.18) 2px 6px 10px;
  }
  & .MuiSvgIcon-root {
    font-size: 24px;
    color: #fff;
  }
`;

const OnDesktopMessage = styled.div`
  padding: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  & > h2 {
    font-family: "Comfortaa";
    font-weight: 100;
    font-size: 18px;
    text-align: center;
  }
`;
